import React, { useEffect } from "react";
import "./css/Testimony.scss";
import { testimony_1 } from "../../assets";
import { useSelector } from "react-redux";
const Testimony = () => {

    useEffect(() => {
        window.scroll(0,0);
    },[])
    const {user} = useSelector((state) => state.user)
    return (
        <div className="testimony-container">
            {/* Hero Section */}
            <div className="testimony-hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Reviews on Workcroft</h1>
                        <p>Read Genuine Reviews and Testimonials from Clients Who Have Experienced Success with Workcroft</p>

                        {!user && <a href="/signup"><button className="primary-btn">Join Workcroft</button></a>}
                    </div>
                </div>
                <div className="hero-image">
                    <img src={testimony_1} alt="Person working on laptop" />
                </div>
            </div>

            <div className="all-testimony" >
                <h1 className="subHeading">Testimony</h1>
                <br />
                <div className="testimonyContainer">

                    <p className="testimonyHeading">My lord, your consulting firm Blackcoffer did an awesome job on this academic research project for me. You will be getting more academic research assignments from me and likely consulting research from my consulting firm. I cannot thank you enough.</p>

                    <div className="testimonyAuthor">
                        <p >Professor of Finance and Economics <br /> University of Texas </p>
                    </div>
                </div>
                <br />

                <div className="testimonyContainer">
                    <p className="testimonyHeading">Be sure this project was very positive for Blackcoffer and I (or maybe others in our university) really hope to contact you in the future for another project.</p>
                    <div className="testimonyAuthor">
                        <p>Professor of Finance and Accounting <br /> London Business School </p>
                    </div>
                </div>
                <br />

                <div className="testimonyContainer">
                    <p className="testimonyHeading">Thanks for the hard work you guys did for me. 98% accuracy is pretty good, especially now that the data has been validated by my colleague. That made me smile. Look forward to working with Blackcoffer team on many more exciting projects to come. Truly is exciting news!</p>
                    <div className="testimonyAuthor">
                        <p>
                            Faculty of Accounting <br /> University of Arkansas
                        </p>
                    </div>
                </div>
                <br />

                <div className="testimonyContainer">
                    <p className="testimonyHeading">Data looks outstanding. Thanks for making my day. You helped me to accelerate my research project especially when I was facing challenges to collect, process, mine and prepare an analytics ready data. It is a ton help for me and my colleague. Blackcoffer team is awesome.</p>
                    <div className="testimonyAuthor">
                        <p>
                            Professor of Accounting <br /> Cornell University
                        </p>
                    </div>
                </div>
                <br />

                <div className="testimonyContainer">
                    <p className="testimonyHeading">Thanks for your hard work, appreciated. It was a sincere pleasure to collaborate with you and your team, and as I expressed earlier I was incredibly surprised by how you mastered a difficult and not common topic. I will surely re-contact you if in need. Have a great continuation.</p>
                    <div className="testimonyAuthor">
                        <p>
                            Business Owner <br /> Geneva, Switzerland
                        </p>
                    </div>
                </div>
                <br />

                <div className="testimonyContainer">
                    <p className="testimonyHeading">Blackcoffer has provided excellent big data management expertise as a partner in groundbreaking research and development. I look forward to continuing our work with great results and innovation.</p>
                    <div className="testimonyAuthor">
                        <p>
                            Political Leader & Business Owner <br /> One of a leading think tank in the U.S.A.
                        </p>

                    </div>
                </div>
                <br />
            </div>
        </div>
    );
};

export default Testimony;
