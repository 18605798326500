// TalentOpportunities.jsx
import React from 'react';
import '../css/LandingPageNew.scss';
import { Opportunity } from '../../../assets';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const TalentOpportunities = () => {
  const { user } = useSelector((state) => state.user);

  const features = [
    "Discover opportunities at every stage of your freelance career",
    "Manage your schedule, location, and work preferences",
    "Discover various earning opportunities"
  ];

  return (
    <div className="opportunities-container">
      <div className="image-section">
        <img
          src={Opportunity}
          alt="Professional working on laptop"
        />
      </div>

      <div className="content-section">
        <span className="label">For talent</span>

        <h1 className="title">
          Discover excellent<br />
          opportunities
        </h1>

        <p className="description">
          Connect with clients who inspire you and propel your career or
          business to new levels.
        </p>

        <div className="features-list">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              {feature}
            </div>
          ))}
        </div>

        <Link to={`${ !user ? "/matchedHourlyProject/all" : (user?.role === "User" ? "/matchedHourlyProject/all" : "/") }`}>
          <button className="opportunities-btn">
            Find opportunities
          </button>
        </Link>
      </div>
    </div>
  );
};

export default TalentOpportunities;