import React, { useState } from 'react';
import '../css/LandingPageNew.scss';
import { useNavigate } from 'react-router-dom';
const TrendingSkills = () => {

    const navigate = useNavigate()
    const [activeSkills, setActiveSkills] = useState('top'); // Default to "Top Skills"

    // Sample data for skills
    const topSkills = [
        'Generative AI Specialists', 'Data Entry Specialists', 'Video Editors',
        'Data Analyst', 'Shopify Developer', 'Ruby on Rails Developer',
        'Android Developer', 'Bookkeeper', 'Content Writer',
        'Copywriter', 'Data Scientist', 'Front-End Developer',
        'Game Developer', 'Graphic Designer', 'iOS Developer',
        'Java Developer'
    ];
    const topSkillsTwo = [
        'JavaScript Developer', 'Logo Designer',
        'Mobile App Developer', 'PHP Developer', 'Python Developer',
        'Resume Writer', 'SEO Expert', 'Social Media Manager',
        'Software Developer', 'Software Engineer', 'Technical Writer',
        'UI Designer', 'UX Designer', 'Virtual Assistant',
        'Web Designer', 'WordPress Developer'
    ]

    const trendingSkills = [
        "Generative AI Specialists",
        "Prompt Engineering Specialists",
        "AI Content Creators",
        "ChatGPT Specialists",
        "AI Chatbot Specialists",
        "Stable Diffusion Artists",
        "Blockchain",
        "Go development",
        "Node.js",
        "Vue.js",
        "HR consulting",
        "Microsoft Power BI",
        "Instructional design",
        "React.js",
        "Videographers",
        "HTML5 Developers"
    ]
    const trendingSkillsTwo = [
        "Ghostwriters",
        "Unity 3D Developers",
        "Business Consultants",
        "Coders",
        "Web Developers",
        "Illustrators",
        "Google AdWords Experts",
        "Digital Marketers",
        "Project Managers",
        "Ruby Developers",
        "AngularJS Developers",
        "Full Stack Developers",
        "React Native Developers",
        "Swift Developers",
        "CSS Developers",
        "Back End Developers"
    ]
    // Function to handle switching skills
    const handleSkillSwitch = (type) => {
        setActiveSkills(type); // Switch between 'top' and 'trending'
    };  
    const handleNavigateToFindTalent = (skill) => {
        // Store category in sessionStorage instead of navigation state
        console.log(skill);
        
        sessionStorage.setItem('selectedTopSkills', skill);
        navigate("/freelancer-details");
    };
  

    return (
        <div className="skills-container">
            <div className="skills-grid">

                <div className="skills-column">
                    <h1 className={activeSkills === 'top' ? 'skills-title active' : 'skills-title'} onClick={() => handleSkillSwitch('top')}>Top skills</h1>
                    <h2 className={activeSkills === 'trending' ? 'trending-title active' : 'trending-title'} onClick={() => handleSkillSwitch('trending')}>Trending skills</h2>
                </div>

                {activeSkills === "top" && <div className="skills-column-two-center">
                    {topSkills?.map((skill, index) => (
                        <div key={index} className="skill-item" onClick={() => handleNavigateToFindTalent(skill)}>{skill}</div>
                    ))}
                </div>}
                {activeSkills === "top" && <div className="skills-column-two">
                    {topSkillsTwo?.map((skill, index) => (
                        <div key={index} className="skill-item" onClick={() => handleNavigateToFindTalent(skill)}>{skill}</div>
                    ))}
                </div>}

                {activeSkills === "trending" && <div className="skills-column-two-center">
                    {trendingSkills?.map((skill, index) => (
                        <div key={index} className="skill-item" onClick={() => handleNavigateToFindTalent(skill)}>{skill}</div>
                    ))}
                </div>}
                {activeSkills === "trending" && <div className="skills-column-two">
                    {trendingSkillsTwo?.map((skill, index) => (
                        <div key={index} className="skill-item" onClick={() => handleNavigateToFindTalent(skill)}>{skill}</div>
                    ))}
                </div>}
            </div>
        </div>
    );
};

export default TrendingSkills;