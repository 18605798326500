import React from 'react'
import "../css/LandingPageNew.scss";
import { Performance,edit,pin,shield } from '../../../assets';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const PerformanceSection = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <section className="performance-section">
        <div className="content-wrapper">
          <div className="image-container">
            <img src={Performance} alt="Colleagues high-fiving" className="high-five-image" />
          </div>
          
          <div className="content">
            <h2>Elevate your work performance</h2>
            
            <div className="features">
              <div className="feature">
                <img src={edit} alt="" className='icons' />
                <div className="feature-text">
                  <h3>There are no charges for joining</h3>
                  <p>Register and browse professionals or let us connect you with the best talent available.</p>
                </div>
              </div>

              <div className="feature">
                <img src={pin} alt="" className='icons'/>
                <div className="feature-text">
                  <h3>Post a job listing and hire top talent</h3>
                  <p>Finding talent doesn't have to be a chore. Let us find and connect you with top pro!</p>
                </div>
              </div>

              <div className="feature">
                <img src={shield} alt="" className='icons'/>

                <div className="feature-text">
                  <h3>Collaborate with top professionals without overspending</h3>
                  <p>Workcroft offers affordable rates to enhance your work and benefit from low transaction rates.</p>
                </div>
              </div>
            </div>

            <div className="cta-buttons">
                {!user && <a href='/signup' style={{color:"white"}} > <button className="sign-up-btn"> Sign up for free </button> </a>}

              <Link to="/how-to-hire" style={{color:"#1f57c3"}}> <button className="learn-more-btn"> Learn how to hire </button> </Link>
            </div>
          </div>
        </div>
      </section>
  )
}

export default PerformanceSection