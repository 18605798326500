import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteIcon from "@mui/icons-material/Delete";

const MessageOptionModal = ({ 
    senderId, 
    conversationId, 
    messageId, 
    handleRemoveMessage, 
    allData, 
    handleEditMessage,
    setEditFlag,
    setchatMessage,
    setEditMessageId,
    setEditConversationId,
    setReplyMessage,
    setReplyFlag,
    setDeleteFlag,
    wholeData,
    setDeleteMessage,
    user // Pass the user object as a prop
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    console.log(wholeData);
    
    // Memoize the conversation ID to prevent unnecessary re-renders
    const isObjectId = (id) => /^[0-9a-fA-F]{24}$/.test(id);

    const memoizedConversationId = React.useMemo(() => {
        console.log("Provided conversationId -----", conversationId);
    
        // Check if the provided conversationId is a valid ObjectId
        if (conversationId && isObjectId(conversationId)) {
            return conversationId; // Use it directly if valid ObjectId
        }
    
        console.log("Looking for roomId:", conversationId);
    
        // If not, treat it as a roomId and find the associated _id in allData
        if (wholeData && wholeData.roomId === conversationId) {
            console.log("in");
            
            console.log("Found matching roomId in allData:", wholeData._id);
            return wholeData._id; // Return the associated _id
        }
    
        return null; // Return null if nothing matches
    }, [conversationId, allData]);
    
    console.log(memoizedConversationId,messageId);
    
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Check if message can be deleted (within 1 hour)
    const isWithinDeletionTime = React.useMemo(() => {
        if (!allData?.timestamp) return false;
        
        const messageTime = new Date(allData.timestamp);
        const currentTime = new Date();
        const timeDifference = currentTime - messageTime;
        
        // Convert 1 hour to milliseconds (1 hour = 60 * 60 * 1000 milliseconds)
        const oneHourInMilliseconds = 60 * 60 * 1000;
        
        return timeDifference <= oneHourInMilliseconds;
    }, [allData?.timestamp]);

    // Determine options based on sender and time
    const optionsWithIcons = React.useMemo(() => {
        // Ensure senderId is compared with user._id
        const isMessageSender = senderId === user._id;
        
        return isMessageSender 
            ? [
                ...(isWithinDeletionTime ? [{ label: "Edit", icon: <EditIcon fontSize="small" /> }] : []),
                { label: "Reply", icon: <ReplyIcon fontSize="small" /> },
                ...(isWithinDeletionTime ? [{ label: "Delete", icon: <DeleteIcon fontSize="small" /> }] : []),
            ]
            : [
                { label: "Reply", icon: <ReplyIcon fontSize="small" /> }
            ];
    }, [senderId, user._id, isWithinDeletionTime]);

    return (
        <div>
            <IconButton 
                aria-label="more" 
                id="long-button" 
                aria-controls={open ? "long-menu" : undefined} 
                aria-expanded={open ? "true" : undefined} 
                aria-haspopup="true" 
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: 48 * 4.5,
                            width: "20ch",
                        },
                    },
                }}
            >
                {optionsWithIcons.map(({ label, icon }) => (
                    <MenuItem
                        key={label}
                        onClick={() => {
                            handleClose();
                            if (label === "Edit") {
                                setEditFlag(true);
                                setchatMessage(allData?.content);
                                setEditMessageId(messageId);
                                // Use memoized conversation ID
                                setEditConversationId(memoizedConversationId);
                            } else if (label === "Reply") {
                                setchatMessage('');
                                setReplyFlag(true);
                                setReplyMessage({ conversationId: memoizedConversationId,   parentMessageId: allData._id  }); 
                            } else if (label === "Delete") {
                                // Use memoized conversation ID
                                setDeleteFlag(true)
                                setDeleteMessage({  
                                    conversationId: memoizedConversationId,  
                                    messageId: messageId  
                                });
                                // handleRemoveMessage(memoizedConversationId, messageId);
                            }
                        }}
                        style={{ 
                            fontSize: "14px", 
                            display: "flex", 
                            alignItems: "center", 
                            gap: "8px" 
                        }}
                    >
                        {icon}
                        <span>{label}</span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default React.memo(MessageOptionModal);