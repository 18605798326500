import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { talentDirectContract,talentDirectContract_2,talentDirectContract_3 } from "../../assets";
import "./css/DirectContract.scss";
import { useSelector } from "react-redux";

const TalentDirectContract = () => {
    const {user} = useSelector((state) => state.user)
    useEffect(() => {
        window.scroll(0,0);
    },[])
    const steps = [
        {
            number: 1,
            title: "Create a contract",
            description: "Afterward, we forward it to your client.",
        },
        {
            number: 2,
            title: "Your client accepts it",
            description: "Ensure the payment method (direct using any channel).",
        },
        {
            number: 3,
            title: "You get paid",
            description: "You get paid directly from your client.",
        },
    ];
    return (
        <div className="direct-contract-container">
            {/* Hero Section */}
            <div className="hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Manage your business from a single platform</h1>
                        <p>Handle contracts and securely receive payments from any client, regardless of whether you connected through Workcroft.</p>

                        <Link to={!user ? "/login" : "/matchedHourlyProject/all"}><button className="primary-btn">Get started</button></Link>

                    </div>
                    <p className="signup-text">
                    Are you a client? View information
                        <Link to="/direct-contracts" className="signup-link">
                            &nbsp;here
                        </Link>
                    </p>

                    
                </div>
                <div className="hero-image">
                    <img src={talentDirectContract} alt="Person working on laptop" />
                </div>
            </div>

            <section className="how-it-works">
                <h2>How to pay freelancers</h2>
                <div className="steps-container">
                    {steps.map((step, index) => (
                        <div key={index} className="step-item">
                            <div className="step-number">{step.number}</div>
                            <div className="content">
                                <h3>{step.title}</h3>
                                <p>{step.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className="reasons">
                <div className="reasons__content">
                    <div className="reasons__image">
                        <img src={talentDirectContract_2} alt="Team meeting in modern office space" />
                    </div>
                    <div className="reasons__text">
                        <h2>Why Direct Contracts?</h2>

                        <div className="reasons__list">
                            <div className="reason">
                                <h3>Payment protection</h3>
                                <p>You ensure that client pays you directly. Our platform is not promissing any payment protection.</p>
                            </div>

                            <div className="reason">
                                <h3>One small fee</h3>
                                <p>No payment made via our platform, no fee charged by us.</p>
                            </div>

                            <div className="reason">
                                <h3>Easy for your client</h3>
                                <p>Clients can pay by credit card, PayPal, or ACH bank transfers in the U.S.</p>
                            </div>
                            <div className="reason">
                                <h3>Help with disputes</h3>
                                <p>We'll assist if your project encounters any issues.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="discover">
                <div className="discover__content">
                    <div className="discover__icon">
                        <img src={talentDirectContract_3} alt="" className="search-icon" />
                    </div>

                    <div className="discover__text">
                        <h2>Work securely with your non-Workcroft clients</h2>
                        <Link to={!user ? "/login" : "/matchedHourlyProject/all"}><button className="button button--success">Get Started</button></Link>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TalentDirectContract;
