import React,{useEffect} from 'react';
import "./css/LearnMore.scss"
import { learnMore } from '../../assets';
import { Link } from 'react-router-dom';
const PlatformChargesAndPayment = () => {

    useEffect(() => {
        window.scroll(0,0);
    },[])
    return (
        <div className="platform-charges-payment">
            <div className="container">
                <h1 className="title">Platform Charges and Payment Structure</h1>
                <p className="description">
                    We charge a nominal connection fee to freelancers to cover the cost of submitting bid proposals and enhancing profile visibility for attracting potential employers. This fee ensures that your profile is highlighted, enabling you to stand out and increase your chances of securing projects.
                </p>

                <section className="for-employers">
                    <h2 className="section-title">For employers:</h2>
                    <p>
                        There is no fee to post projects. Employers are free to list as many projects as needed, search for skilled freelancers, and initiate conversations to discuss project goals and expectations. The platform provides tools for seamless communication, allowing employers and freelancers to explore potential synergies and align on project scopes.
                    </p>
                    <p>
                        Once an employer selects a freelancer, they can mutually agree upon the freelancer's rates or the project budget, making the negotiation process transparent and fair. Employers and freelancers can also discuss and agree on a payment structure that suits both parties. Our platform is flexible and supports a variety of payment methods. Freelancers and employers can choose the method that is most convenient and beneficial for them, including but not limited to PayPal, Payoneer, Wise, wire transfer, WorldRemit, and direct bank transfers.
                    </p>
                    <p>
                        This structure is designed to create an open, collaborative environment where both parties can negotiate terms that suit their preferences and secure smooth transactions throughout the project lifecycle.
                    </p>
                    <p>
                        There is no charge to employers for posting projects. Employers can post projects, can find talents, can chat and discuss the project synergies with talents. Further employers can mutually agree with freelancers regarding freelancer rates or project budgets and can assign freelancers the project. Further Employers can mutually agree with freelancers regarding how freelancers would like to get paid for their work and contributions. They both can mutually agree on any payment method such as using PayPal, Payoneer, Wise, wire transfer, worldremit, direct bank-to-bank transfer, or any method those are mutually beneficial.
                    </p>
                </section>

                <section className="for-freelancers">
                    <h2 className="section-title">For Freelancers:</h2>
                    <p>
                        We charge connection fees that are necessary for submitting bid proposals and increasing your profile visibility to potential employers.
                    </p>
                </section>

                <div className="support">
                    <div className="content">
                        <h3 className="support-title">Workcroft Help</h3>
                        <p className="support-text">Do you need additional help?</p>
                        <Link to="/support">
                        <button className="support-btn">Contact Support</button>
                        </Link>
                    </div>
                    <div className="contentImg">
                        <img src={learnMore} alt="" />

                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlatformChargesAndPayment;