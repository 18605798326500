import React from "react";
import "../Home/css/LandingPageNew.scss";
import { Link } from "react-router-dom";
const Footer = () => {
    const data = {
        client: [
            { label: "How to hire", link: "/how-to-hire" },
            { label: "Talent Marketplace", link: "/talent-marketplace" },
            { label: "Hire an agency", link: "/hire-an-agency" },
            { label: "Enterprise", link: "/enterprise" },
            { label: "Any Hire", link: "/any-hire" },
            { label: "Contract-to-hire", link: "/contract-to-hire" },
            { label: "Direct Contracts", link: "/direct-contracts" },
            { label: "Hire Worldwide", link: "/hire-worldwide" },
        ],
        talent: [
            { label: "How to find work", link: "/how-to-find-work" },
            { label: "Direct Contracts", link: "/talent-direct-contracts" },
        ],
        resources: [
            { label: "Help & support", link: "/support" },
            { label: "Terms & Condition", link: "/term-condition" },
            { label: "User Agreement", link: "/user-agreement" },
            { label: "Privacy", link: "/privacy" },
            { label: "GDPR", link: "/gdpr" },
        ],
        company: [
            { label: "About Us", link: "/about-us" },
            { label: "Contact Us", link: "/contactus" },
            { label: "How it Works", link: "/how-it-works" },
            { label: "Testimony", link: "/testimony" },
            { label: "Story", link: "/story" },
            { label: "FAQs", link: "/faqs" },
        ],
    };

    return (
        <div className="footer-wrapper">
            <div className="footer-container">
                <div className="footer-grid">
                    <div className="footer-column">
                        <h4 className="title">For Client</h4>
                        {data.client.map((item, index) => (
                            <Link to={item.link} key={index}>
                                <div key={index} className="footer-item">
                                    {item.label}
                                </div>
                            </Link>
                        ))}
                    </div>
                    <div className="footer-column">
                        <h4 className="title">For Talent</h4>

                        {data.talent.map((item, index) => (
                            <Link to={item.link} key={index}>
                                <div key={index} className="footer-item">
                                    {item.label}
                                </div>
                            </Link>
                        ))}
                    </div>
                    <div className="footer-column">
                        <h4 className="title">Resources</h4>
                        {data.resources.map((item, index) => (
                            <Link to={item.link} key={index}>
                                <div key={index} className="footer-item">
                                    {item.label}
                                </div>
                            </Link>
                        ))}
                    </div>

                    <div className="footer-column">
                        <h4 className="title">Company</h4>
                        {data.company.map((item, index) => (
                            <Link to={item.link} key={index}>
                                <div key={index} className="footer-item">
                                    {item.label}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="copyrightWrapper">
                    <div className="copyright">
                        <h5>@ Copyright 2024 Workcroft</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
