import React, { useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

function EmployerprotectedRoute({Component}) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
      const userType = localStorage.getItem('userType');
      
      if (!userType) {
          const fullPath = location.pathname + location.search + location.hash;
          console.log("from employer route ", fullPath);
          
          localStorage.setItem('intendedPath', fullPath);
          navigate('/login');
      } else if (userType !== 'Employer') {
          navigate('/notfound');
      }
  }, [navigate, location]);

  return <>{localStorage.getItem('userType') === 'Employer' ? <Component /> : null}</>;
}

export default EmployerprotectedRoute;
