import React, { useRef, useCallback, useMemo } from "react";
import "./css/Messageemp.scss";
import mentionstyle from "../Student/mention";
import "../Student/css/MessageStudent.scss";
import io from "socket.io-client";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { LuSearch } from "react-icons/lu";
import { RxCross1 } from "react-icons/rx";
import { FiInfo } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { MdOutlineMarkEmailUnread, MdMailOutline } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FiExternalLink } from "react-icons/fi";
import { PiChecks } from "react-icons/pi";
import sendAssignmentLogo from "../../assets/images/sendassignment.png";
import reportLogo from "../../assets/images/report.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { active, index, style, timeFormat } from "d3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiChatOffLine } from "react-icons/ri";
import Dropdown from "./Dropdown";
import { button } from "@material-tailwind/react";
import { MdKeyboardBackspace } from "react-icons/md";
import { FaBagShopping } from "react-icons/fa6";
import { Mention, MentionsInput } from "react-mentions";
import { IoLocation } from "react-icons/io5";
import MessageOptionModal from "../Dropdown/MessageOptionsModal";
import { replyArrow, cross } from "../../assets";
import DeleteMessageModal from "../Dropdown/DeleteMessageModal";
// const socket = io.connect("http://localhost:8000");
const socket = io.connect("https://workcroft.com");
// const socket = io.connect('http://34.122.119.232:8000/');

const reason = [
    {
        id: 1,
        name: "Provided fake documents",
    },
    {
        id: 2,
        name: "Didn't respond in a professional manner",
    },
    {
        id: 3,
        name: "Very rude during the interview",
    },
    {
        id: 4,
        name: "Mentioned fake details about previous internships",
    },
    {
        id: 5,
        name: "Others",
    },
];

function MessageEmployer() {
    const [viewMessage, setViewMesage] = useState(false);
    const wrapLinks = (content) => {
        if (!content) return "";
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return content.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    };

    const openReviewPageInNewTab = (data) => {
        window.open(`/review/${data.appliedJobId._id}`);
    };

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
    }, []);
    const [Id, setId] = useState("");
    const [candidatename, setCandidateName] = useState("");
    const [photourlclnt, setphotourlclnt] = useState("");
    const [photourlcand, setphotourlcand] = useState("");
    const [allRooms, setAllRooms] = useState([]);
    const [chatMessage, setchatMessage] = useState("");
    const [history, setHistory] = useState([]);
    const { user } = useSelector((state) => state.user);

    const { state } = useLocation();
    const [companyName, setCompanyName] = useState("");
    const [employerLocation, setEmployerLocation] = useState("");
    const [roomId, setRoomId] = useState("");
    const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
    const [chatActive, setChatActive] = useState(0);
    const navigate = useNavigate();
    const [hireLoading, setHireLoading] = useState(false);
    const [loader, setLoader] = useState(true);
    const [notIntrestedLoading, setNotIntrestedLoading] = useState(false);
    const [inputDataSendAssigment, setInputDataSendAssigment] = useState({
        description: "",
        date: "",
    });


    const [search, setSearch] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [hired, setHired] = useState(false);
    useEffect(() => {
        if (history.length !== 0 && allRooms.length !== 0 && allRooms[0].applicantJobId) {
            const room = allRooms.find((data) => {
                return data.appliedJobId._id === history.appliedJobId;
            });
            if (room?.appliedJobId?.status !== "Hired") setHired(true);
        }
    }, [history, allRooms]);

    /* const [activeTab, setActiveTab] = useState('all');
  
    const handleTabClick = async (tab) => {
      setActiveTab(tab);
      if (tab === 'all') {
        allMessages();
      } else if (tab === 'unread') {
        unReadMessages();
      }
    };
  
    const getActiveButtonStyle = () => {
      if (activeTab === 'all') {
        return {
          '--active-width': '119.417px',
          '--transform-x': '-1px',
        };
      } else {
        return {
          '--active-width': '103.0833px',
          '--transform-x': '112.417px',
        };
      }
    };
  */
    useEffect(() => {
        socket.on("test_message", (data) => { });
        if (allRooms && allRooms.length > 0 && chatActive == 0) {
            setChatActive(allRooms[0].roomId);
        }
    });

    const LoadingTrue = (data) => {
        if (data == "Hired") {
            setHireLoading(true);
        }
        if (data == "NotIntrested") {
            setNotIntrestedLoading(true);
        }
    };

    const LoadingFalse = (data) => {
        if (data == "Hired") {
            setHireLoading(false);
        }
        if (data == "NotIntrested") {
            setNotIntrestedLoading(false);
        }
    };

    const handleInputSendAssigment = (e) => {
        setInputDataSendAssigment({
            ...inputDataSendAssigment,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmitSendAssigment = () => {
        // Put your code here...
        // console.log(inputDataSendAssigment);
        setInputDataSendAssigment({
            ...inputDataSendAssigment,
            description: "",
            date: "",
        });
    };

    const [inputDataSendReport, setInputDataSendReport] = useState({
        reason: "Provided fake documents",
        description: "",
        otherReason: "",
        jobId: "",
        appliedJobId: "",
        employerId: "",
        applicantId: "",
    });

    const handleInputSendReport2 = () => {
        setInputDataSendReport({
            ...inputDataSendReport,
            jobId: allRooms[chatActive].jobId,
            appliedJobId: allRooms[chatActive].appliedJobId,
            employerId: allRooms[chatActive].participants[0],
            applicantId: allRooms[chatActive].participants[1],
        });
    };

    const handleInputSendReport = (e) => {
        setInputDataSendReport({
            ...inputDataSendReport,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmitSendReport = () => {
        // Put your code here...
        setInputDataSendReport({
            ...inputDataSendReport,
            reason: "Provided fake documents",
            description: "",
            otherReason: "",
        });
    };

    const [selectedItem, setSelectedItem] = useState("");

    const GetRoomData = async () => {
        // setLoader(true);
        try {
            const id = user._id;
            const { data } = await axios.post(
                `/chats/all-conversations/${id}`,
                {
                    jobId: selectedItem && selectedItem._id ? selectedItem._id : "",
                    search: "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );

            // data.sort((a, b) => {
            //   const lastMessageTimeA = new Date(
            //     a.messages[a.messages.length - 1]?.timestamp || 0
            //   );
            //   const lastMessageTimeB = new Date(
            //     b.messages[b.messages.length - 1]?.timestamp || 0
            //   );
            //   return lastMessageTimeB - lastMessageTimeA;
            // });

            setAllRooms(data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
        }
    };

    /*const unReadMessages = async () => {
      setChatActive(0);
      try {
        setLoader(true);
        setHistory('');
        setCompanyName('');
        setSelectedItem('');
        setSearch('');
        setunreadMessages('unread');
        const id = user._id;
        const response = await axios.post(
          `/chats/all-conversations/${id}`,
          {
            jobId: '',
            search: '',
            unreadMessages: 'unread',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              token: localStorage.getItem('token'),
            },
          }
        );
        const data = response.data;
        setAllRooms(data);
        if (data && data.length > 0) {
          await joinRoom(
            data[0].roomId,
            data[0].participants[1],
          );
        }
        setLoader(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        // Handle the error, e.g., show an error message to the user
        setLoader(false);
      }
    };
  */

    useEffect(() => {
        GetRoomData();
    }, [socket, history, user]);


    const [conversationId, setConversationId] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);

    const [editMessageId, setEditMessageId] = useState({});
    const [editConversationId, setEditConversationId] = useState({});
    const [replyMessage, setReplyMessage] = useState({});
    const [deleteMessage, setDeleteMessage] = useState({});
    const [receriverData, setReceiverData] = useState({});
    const [receriverDataNew, setReceiverDataNew] = useState({});
    const [replyFlag, setReplyFlag] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [deleteFlag, setDeleteFlag] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    console.log(replyMessage);
    

    useEffect(() => {
        if (deleteConfirm) {
            handleRemoveMessage(deleteMessage.conversationId, deleteMessage.messageId)
            setDeleteFlag(false);
            setDeleteConfirm(false);
        }
    }, [deleteConfirm])

    useEffect(() => {
        if (deleteFlag) {
            setOpen(true)
            setDeleteFlag(false);
        }
    }, [deleteFlag])

    const handleClose = () => {
        setOpen(false);
    };

    // console.log("reply msg ", conversationId);

    // const [editStatus, setEditStatus] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            const id = user._id;
            const { data } = await axios.post(
                `/chats/all-conversations/${id}`,
                {
                    jobId: selectedItem && selectedItem._id ? selectedItem._id : "",
                    search: "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );
            if (state) {
                const room = data.find((item) => item.roomId === state);
                if (room) {
                    joinRoom(room.roomId, room.participants[1], room.participants[0]);
                } else {
                    console.log("Room not found");
                }
            } else {
                joinRoom(data[0].roomId, data[0].participants[1], data[0].participants[0]);
            }
            setAllRooms(data);
            setLoader(false);
        };

        fetchData();
    }, [selectedItem]);





    const [once, setOnce] = useState(true);
    const [temp, setTemp] = useState("");

    // console.log(conversationId);


    useEffect(() => {
        if (once) {
            const roomIds = allRooms && allRooms.map((data) => data.roomId);
            roomIds.forEach((roomIds) => {
                //joinRoom(roomIds.roomId,roomIds.participants[0].organisationName);
                socket.emit("join_room", roomIds.roomId);
                socket.on("room_data", (data) => {
                    setTemp(data);
                });
            });
            setOnce(false);
        }
    }, [allRooms]);

    const [historyChange, setHistoryChange] = useState(false);
    useEffect(() => {
        if (allRooms && allRooms.length > 0 && !hasJoinedRoom) {
            if (state) {
                const room = allRooms.find((item) => item.roomId === state);

                if (room) {
                    joinRoom(room.roomId, room.participants[1], room.participants[0]);
                } else {
                    console.log("Room not found");
                }
            } else {
                joinRoom(allRooms[0].roomId, allRooms[0].participants[1], allRooms[0].participants[0]);
            }
            setHasJoinedRoom(true); // Set the flag to true after joining room
        } else if (allRooms && allRooms.length > 0 && historyChange) {
            const room = allRooms.find((item) => item.roomId === chatActive);
            joinRoom(chatActive, room.participants[1], room.participants[0]);
            /* socket.emit('join_room', chatActive);
            socket.on('room_data', (data) => {
              setHistory(data);
            });*/

            setHistoryChange(false);
        }
    }, [allRooms, chatActive, hasJoinedRoom]);

    useEffect(() => {
        // An array of your room IDs
        const roomIds = allRooms && allRooms.map((data) => data.roomId);
        // Function to set up event listener for a specific room
        const setupRoomListener = (roomId2) => {
            socket.on(`receive_message_${roomId2}`, (data) => {
                console.log(`Received message for room ${roomId2}:`, data);
                GetRoomData();
                if (roomId2 == chatActive) {
                    setHistoryChange(true);
                }
            });
        };

        // Set up event listeners for each room
        roomIds.forEach((roomIds) => {
            setupRoomListener(roomIds);
        });
        // Cleanup the event listeners on component unmount
        return () => {
            roomIds.forEach((roomIds) => {
                socket.off(`receive_message_${roomIds}`);
            });
        };
    });
    const [cand, setcand] = useState({});

    useEffect(() => {
        const receriverName = allRooms.find((data) => data._id === conversationId)?.participants[1];
        console.log(receriverName);
        setReceiverData(receriverName)
        // setReceiverDataNew(receriverName)

    }, [conversationId])

    console.log(receriverData);

    const initialRoomId = useMemo(() => {
        return allRooms && allRooms.length > 0 ? allRooms[0]._id : null;
    }, [allRooms]);

    // Persistent conversation ID setter
    const safeSetConversationId = useCallback((id) => {
        setConversationId(prevId => {
            // Only update if the new ID is different and valid
            return id || prevId;
        });
    }, []);

    // Room joining logic
    const joinRoom = useCallback((roomId1, participant, client, conversationid) => {
        // Ensure conversationid is used, fallback to existing or initial
        const finalConversationId = conversationid || selectedRoom || initialRoomId;

        // Set selected room and conversation ID
        setSelectedRoom(roomId1);
        safeSetConversationId(finalConversationId);

        // Rest of your existing logic
        setCompanyName(participant.name);
        setCandidateName(client?.name);
        setcand(participant);
        setphotourlcand(participant?.resume?.PersonalDetails?.photo?.url);
        setId(participant?._id);
        setphotourlclnt(client?.avatar?.url);
        setRoomId(roomId1);

        socket.emit("join_room", roomId1);
        socket.on("room_data", (data) => {
            setHistory(data);
            setChatActive(roomId1);
            if (data.messages.length > 0 &&
                data.messages[data.messages.length - 1].status == "unread" &&
                data.messages[data.messages.length - 1].receiver == user._id) {
                axios.post(
                    "/chats/read-messages",
                    { roomId1 },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );
            }
        });
        GetRoomData();
    }, [socket, user?._id, selectedRoom, initialRoomId, safeSetConversationId]);

    // Initial conversation ID setting
    useEffect(() => {
        // Only set if no conversation ID is set and rooms exist
        if (!conversationId && initialRoomId) {
            safeSetConversationId(initialRoomId);
        }
    }, [initialRoomId, conversationId, safeSetConversationId]);

    // Messaging send effect to preserve conversation ID
    useEffect(() => {
        // If conversation ID becomes undefined after message send
        // Restore it from the selected room or initial room
        if (!conversationId) {
            const restoreId = selectedRoom || initialRoomId;
            safeSetConversationId(restoreId);
        }
    }, [conversationId, selectedRoom, initialRoomId, safeSetConversationId]);


    const sendMessage = async (data, jobId) => {
        if (chatMessage !== "") {
            const content = chatMessage;
            const participants = [user._id, data];

            try {
                // Ensure we have a valid conversation ID
                const finalConversationId = conversationId || selectedRoom || initialRoomId;

                // Prepare message data
                const data1 = {
                    roomId,
                    message: content,
                };

                // Send message to backend
                const response = await axios.post(
                    "/chats/send-messages",
                    {
                        jobId,
                        participants,
                        content,
                        conversationId: finalConversationId // Include conversation ID
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );

                // Reset chat message
                setchatMessage("");

                // Find the current room
                const room = allRooms.find((item) => item.roomId === chatActive);

                // Rejoin room to refresh data
                if (room) {
                    joinRoom(
                        chatActive,
                        room.participants[1],
                        room.participants[0],
                        finalConversationId // Pass the conversation ID
                    );
                }

                // Get updated room data
                GetRoomData();

                // Emit socket event
                socket.emit("new_message", data1);

                return response.data; // Return response in case needed
            } catch (error) {
                console.error("Error sending message:", error);
                // toast.error("Failed to send message");
                throw error; // Re-throw to allow caller to handle
            }
        }
    };

    const handleRemoveMessage = async (conversationId, messageId) => {

        try {
            const token = localStorage.getItem("token");

            if (!token) {
                throw new Error("User is not authenticated.");
            }

            // Ensure we have a valid conversation ID
            const finalConversationId = conversationId || selectedRoom || initialRoomId;

            const response = await axios.post(
                `/chats/delete-messages/${finalConversationId}/${messageId}`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        token,
                    },
                }
            );

            if (response.data.success) {
                // Optimistically update local state
                setHistory((prevHistory) => ({
                    ...prevHistory,
                    messages: prevHistory.messages.filter((msg) => msg._id !== messageId),
                }));
                setDeleteFlag(false);

                // Optional: Show success toast
                // toast.success("Message removed successfully");

                return response.data;
            } else {
                console.error("Failed to remove message:", response.data.message);
                // toast.error("Failed to remove message: " + response.data.message);
                setDeleteFlag(false);

                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error("Error removing message:", error.response?.data?.message || error.message || error);
            // toast.error("Error removing message");
            setDeleteFlag(false);

            throw error;
        }
    };

    const handleEditMessage = async (editedMsg, conversationId, messageId) => {
        // Validate input
        if (!editedMsg) {
            console.error("Content is empty. Message cannot be updated.");
            // toast.error("Message content cannot be empty");
            return;
        }

        // Ensure we have a valid conversation ID
        const finalConversationId = conversationId || selectedRoom || initialRoomId;

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("User is not authenticated.");
            }

            // Prepare content
            const content = editedMsg;
            setchatMessage(content);

            // Make the API call to edit the message
            const response = await axios.post(
                `/chats/edit-messages/${finalConversationId}/${messageId}`,
                { content },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token,
                    },
                }
            );

            if (response.data.success) {
                // Update the message in the local state
                setHistory((prevHistory) => {
                    const updatedMessages = prevHistory.messages.map((msg) =>
                        msg._id === messageId
                            ? { ...msg, content, edited: true }
                            : msg
                    );

                    return {
                        ...prevHistory,
                        messages: updatedMessages,
                    };
                });

                // Reset edit state
                setEditFlag(false);
                setchatMessage(""); // Clear chat input

                // Show success toast
                // toast.success("Message edited successfully");

                return response.data;
            } else {
                console.error("Failed to edit message:", response.data.message);
                // toast.error(response.data.message);
                setEditFlag(false);
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error("Error editing message:", error.response?.data?.message || error.message || error);
            // toast.error("Failed to edit message");
            setEditFlag(false);
            throw error;
        }
    };

    const handleReplyMessage = async (msg, conversationId, parentMessageId) => {
        // Validate input
        if (!msg) {
            console.error("Reply message content is empty.");
            toast.error("Reply message cannot be empty");
            return;
        }

        try {
            const token = localStorage.getItem("token");
            if (!token) throw new Error("User not authenticated");

            // Ensure we have a valid conversation ID
            const finalConversationId = conversationId || selectedRoom || initialRoomId;

            const response = await axios.post(
                `/chats/reply-messages/${finalConversationId}/${parentMessageId}`,
                {
                    content: msg,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token,
                    },
                }
            );

            if (response.data.success) {

                // Update local conversation state
                setHistory((prevHistory) => ({
                    ...prevHistory,
                    messages: [...prevHistory.messages, response.data.replyMessage],
                }));

                // Reset reply state
                setReplyFlag(false);
                setchatMessage(""); // Clear chat input

                // Show success toast
                // toast.success("Reply sent successfully");

                return response.data;
            } else {
                console.error("Failed to send reply:", response.data.message);
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error("Error sending reply:", error.response?.data?.message || error.message);
            toast.error("Failed to send reply");
            setReplyFlag(false);
            throw error;
        }
    };

    const scroll = useRef();

    const scrolltoBottom = () => {
        scroll.current.scrollIntoView({
            behaviour: "smooth",
            block: "end",
        });
    };
    useEffect(() => {
        if (scroll.current) {
            scrolltoBottom();
        }
    }, [history]);

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);

    const dropdownRef = useRef();
    const dropdownRef1 = useRef();
    const [isDropdownOpen1, setDropdownOpen1] = useState(false);
    const [isButtonClicked1, setButtonClicked1] = useState(false);

    const [lastTimestamp, setLastTimestamp] = useState(null);
    const [lastDisplayedDate, setLastDisplayedDate] = useState(null);

    useEffect(() => {
        if (history.messages && history.messages.length > 0) {
            const lastMessageTimestamp = history.messages[history.messages.length - 1].timestamp;
            if (lastTimestamp !== lastMessageTimestamp) {
                setLastTimestamp(lastMessageTimestamp);
            }
        }
    }, [history.messages, lastTimestamp]);

    useEffect(() => {
        if (lastDisplayedDate) {
            console.log("Last Displayed Date:", lastDisplayedDate); // You can remove this line later
        }
    }, [lastDisplayedDate]);

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
        setButtonClicked(!isButtonClicked);
    };

    const handleDropdownToggle1 = () => {
        setDropdownOpen1(!isDropdownOpen1);
        setButtonClicked1(!isButtonClicked1);
    };

    const handleDropdownItemClick1 = (item) => {
        setInputDataSendReport({ ...inputDataSendReport, reason: item.name });
        setDropdownOpen1(false);
        setButtonClicked1(false);
        // Add your custom logic for item click here, if needed
        // For example, you can handle the selected item
    };

    const [unreadMessages, setunreadMessages] = useState("");

    const handleSelectButtons = async (ButtonName) => {
        const reqBody = {
            function: ButtonName,
            data: history.appliedJobId,
        };
        try {
            LoadingTrue(ButtonName);
            await axios.post(
                "/api/employer/changeStatusAppliedJob",
                { reqBody },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );
            GetRoomData();
            LoadingFalse(ButtonName);
            toast.success("Status changed successfully");
        } catch (error) {
            toast.error("Something went wrong!!");
            console.log(error);
            LoadingFalse(ButtonName);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.trim().toLowerCase());
    };

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const applyFilters = () => {
        let filteredRooms = [...allRooms];

        /* // Filter by selected item
        if (selectedItem) {
          filteredRooms = filteredRooms.filter((room) => {
            return room.jobId.profile === selectedItem.profile;
          });
        }
    
        // Filter by search term
        if (searchTerm) {
          filteredRooms = filteredRooms.filter((room) => {
            return room.participants.some((participant) =>
              participant.name.toLowerCase().includes(searchTerm)
            );
          });
        }*/

        // Sort filtered rooms based on last message time
        filteredRooms.sort((a, b) => {
            const lastMessageTimeA = new Date(a.messages[a.messages.length - 1]?.timestamp || 0);
            const lastMessageTimeB = new Date(b.messages[b.messages.length - 1]?.timestamp || 0);
            return lastMessageTimeB - lastMessageTimeA;
        });

        // Set the filtered rooms to be displayed
        setAllRooms(filteredRooms);
    };

    // useEffect(() => {
    //   applyFilters();
    // }, [selectedItem, searchTerm]);

    const handleDropdownItemClick = async (item) => {
        setSelectedItem(item);
        setDropdownOpen(false);
        setButtonClicked(false);
        setChatActive(0);
        const chatActive1 = 0;

        try {
            setLoader(true);
            setHistory("");
            setCompanyName("");
            setSearch("");
            const id = user._id;
            const response = await axios.post(
                `/chats/all-conversations/${id}`,
                {
                    jobId: item,
                    search: "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );

            const data = response.data;

            setAllRooms(data);

            if (data && data.length > 0) {
                await joinRoom(data[0].roomId, data[0].participants[1], data[0].participants[0]);
            }
            setLoader(false);
        } catch (err) {
            console.error("Error fetching data:", err);
            // Handle the error, e.g., show an error message to the user
            setLoader(false);
        }
    };

    const allMessages = async () => {
        const chatActive1 = 0;
        setChatActive(0);
        try {
            setLoader(true);
            setHistory("");
            setCompanyName("");
            setSelectedItem("");
            setSearch("");
            setunreadMessages("");
            const id = user._id;
            const response = await axios.post(
                `/chats/all-conversations/${id}`,
                {
                    jobId: "",
                    search: "",
                    unreadMessages: "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );
            const data = response.data;
            setAllRooms(data);
            if (data && data.length > 0) {
                await joinRoom(data[0].roomId, data[0].participants[1], data[0].participants[0]);
            }
            setLoader(false);
        } catch (err) {
            console.error("Error fetching data:", err);
            // Handle the error, e.g., show an error message to the user
            setLoader(false);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
            setButtonClicked(false);
        }
        if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setDropdownOpen1(false);
            setButtonClicked1(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    function isToday(date) {
        const today = new Date();
        return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    }

    // GETTING JOB DATA:
    const [jobData, setJobData] = useState([]);

    const getUserData = async () => {
        try {
            // Initialize an empty array to store all jobs
            let allJobs = [];

            // Loop through each page number
            for (let page = 1; page <= 10; page++) {
                const { data } = await axios.post(
                    "/api/employer/getAllJobs",
                    { page, opportunityType: ["Internship", "Job"] },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                );

                // Concatenate the jobs from the current page to the array
                allJobs = allJobs.concat(data.jobs);
            }

            // Reverse the order if needed
            const jobD = allJobs.reverse();

            // Set the combined jobs data
            setJobData(jobD);
        } catch (error) {
            console.log(error.response.data);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    function calculateCounts(jobData) {
        // Filter out the job data where userApplied field is not empty
        const filteredJobs = jobData.filter((job) => job.userApplied.length > 0);

        return filteredJobs;
    }

    const countedJobs = calculateCounts(jobData);

    const [hover, setHover] = useState(false);
    const [textHover, setTextHover] = useState(Array(countedJobs.length).fill(false));

    const handleTextHoverEnter = (index) => {
        const newTextHover = [...textHover];
        newTextHover[index] = true;
        setTextHover(newTextHover);
    };

    // Function to handle text hover leave event
    const handleTextHoverLeave = (index) => {
        const newTextHover = [...textHover];
        newTextHover[index] = false;
        setTextHover(newTextHover);
    };

    // JSX code to render table
    const getTime = (data) => {
        const timestamp = new Date(data);
        const formattedTime = new Intl.DateTimeFormat("en-IN", {
            timeZone: "Asia/Kolkata",
            hour1: true,
            hour: "2-digit",
            minute: "2-digit",
        }).format(timestamp);
        return formattedTime;
    };

    /* useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          // Run your function here
  
          // Assuming your button has a class name "btnsend"
          const myButton = document.querySelector('.btnsend');
  
          // Check if the button is found
          if (myButton) {
            // Programmatically trigger a click event on the button
            myButton.click();
          } else {
            console.error('Button not found');
          }
        }
      };
  
      // Attach the event listener to the document
      document.addEventListener('keydown', handleKeyPress);
  
      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, []);*/
    const unreadMsg = allRooms.filter((item) => item.status === "unread" && item.sender !== user._id).length;

    useEffect(() => {
        socket.on("new_message", (data) => { });
    });

    const readMesCount = (data) => {
        const unreadCount = data.filter((message) => message.status === "unread" && message.receiver === user._id).length;
        return unreadCount;
    };

    const scroll2 = useRef();

    // MULTILINE MESSAGE OPTION

    const textareaRef = useRef(null);
    const parentDivRef = useRef(null);
    const [textareaRows, setTextareaRows] = useState(1);
    const maxRows = 6;
    const defaultTextareaHeight = 42;

    // const handleKeyDown = (e) => {
    //   if ((e.altKey || e.shiftKey) && e.key === 'Enter') {
    //     e.preventDefault(); // Prevent default behavior of newline insertion
    //     setchatMessage(prevMessage => prevMessage + '\n');
    //   }
    // };
    const handleKeyDown = (e) => {
        if ((e.altKey || e.shiftKey) && e.key === "Enter") {
            e.preventDefault(); // Prevent default behavior

            // Insert a newline at the current cursor position
            const { selectionStart, selectionEnd } = e.target;
            setchatMessage((prevMessage) => prevMessage.slice(0, selectionStart) + "\n" + prevMessage.slice(selectionEnd));

            // Move the cursor position after the newline
            setTimeout(() => {
                e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
            }, 0);
        } else if (e.key === "Enter") {
            e.preventDefault(); // Prevent default form submission behavior

            if (editFlag) {
                // Call handleEditMessage when editing
                if (chatMessage.trim() !== "") {
                    handleEditMessage(chatMessage, editConversationId, editMessageId);
                    setEditFlag(false); // Reset the edit flag after editing
                    setchatMessage("");
                    return // Clear the textarea after editing
                }
            }
            if (replyFlag) {
                // if(chatMessage.trim() === ""){
                handleReplyMessage(chatMessage, replyMessage?.conversationId, replyMessage?.parentMessageId);
                setReplyFlag(false);
                setchatMessage("");
                return // Clear the textarea after editing
                // }
            } else {
                // Send a new message
                if (chatMessage.trim() !== "") {
                    const recipientId = history.participants[0] === user._id ? history.participants[1] : history.participants[0];
                    sendMessage(recipientId, history.jobId);
                    setchatMessage(""); // Clear the textarea after sending
                    return // Clear the textarea after editing
                }
            }
        }
    };


    const handleChange = (e) => {
        setchatMessage(e.target.value);
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            const newRows = Math.min(textarea.value.split("\n").length, maxRows);
            setTextareaRows(newRows);
            // textarea.style.height = `${newRows * 20}px`;
            // textarea.style.height = `${Math.max(textareaRows * 20, defaultTextareaHeight)}px`;
            const newHeight = chatMessage ? Math.max(textareaRows * 20, defaultTextareaHeight) : defaultTextareaHeight;
            textarea.style.height = `${newHeight}px`;
        }
    }, [chatMessage]);
    useEffect(() => {
        const textarea = textareaRef.current;
        const parentDiv = parentDivRef.current;
        if (textarea && parentDiv) {
            const newRows = Math.min(textarea.value.split("\n").length, maxRows);
            setTextareaRows(newRows);
            const newHeight = Math.max(newRows * 30, defaultTextareaHeight);
            textarea.style.height = `${newHeight}px`;
            parentDiv.style.height = `${Math.min(newHeight, 240)}px`;
        }
    }, [chatMessage, textareaRows]);

    useEffect(() => {
        // Scroll textarea to bottom when chatMessage changes
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    }, [chatMessage]);

    useEffect(() => {
        console.log("call");
        
        const receriverNameForPhone = allRooms.find((data) => data._id === conversationId)?.participants[1];
        console.log(receriverNameForPhone);
        // setReceiverData(receriverNameForPhone)
        setReceiverDataNew(receriverNameForPhone)

    }, [conversationId])

    console.log(receriverDataNew);
    console.log("converstaion id",conversationId);
    
    return (
        <>
            <div className="ChatPcView">
                <div
                    className="message-student pb-0"
                    style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        // maxWidth: "1200px",
                        // margin: "0 3rem",
                    }}
                >
                    {/*<h1
          className="mb-3"
          style={{
            fontSize: '18px',
            fontWeight: '600',
            marginBottom: '26px',
            lineHeight: '1.33333',
          }}
        >
          Messages from{' '}
          {selectedItem
            ? `${selectedItem.profile}  ${selectedItem.opportunityType}`
            : 'All internships & jobs'}
          <a id="not-clickable">
            <FiInfo
              style={{
                fontWeight: '400',
                fontSize: '18px',
                marginLeft: '10px',
              }}
            />
          </a>
          <Tooltip
            anchorSelect="#not-clickable"
            place="right"
            style={{
              fontSize: '14px',
              lineHeight: '1.57142857',
              fontWeight: '400',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
              overflow: 'hidden',
              maxWidth: '300px',
              maxHeight: '150px',
              zIndex: '9999',
              padding: '15px',
              borderRadius: '8px',
            }}
          >
            This page shows the chats which you have already started. To start
            chat with other applicants, go to their applications and click on
            'Start Chat'.
          </Tooltip>
        </h1>*/}
                    <div className="chat-box1 shadow">
                        <>
                            <div className="recent-chats">
                                <div
                                    className="chatSlider"
                                    style={{
                                        borderBottom: "1px solid #e0e0e0",
                                        // marginTop: '20px',
                                    }}
                                >
                                    {/* <div
                      className="dropdown"
                      ref={dropdownRef}
                      style={{ width: '90%', paddingTop: '8px' }}
                    >
                      <button
                        className="btn chatSliderDropdown dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded={isDropdownOpen}
                        style={{
                          color: 'white',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'space-between',
                          position: 'relative',
                          padding: '11px',
                          width: '100%',
                        }}
                        onClick={handleDropdownToggle}
                      >
                        <span
                          style={{
                            fontSize: '14px',
                            lineHeight: '1.14285714',
                            fontWeight: '400',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            color: '#484848',
                          }}
                        >
                          {selectedItem
                            ? selectedItem.profile
                            : 'All internships & jobs'}
                        </span>
                        {isDropdownOpen || isButtonClicked ? (
                          <IoIosArrowUp
                            style={{
                              fontSize: '24px',
                              color: '#666',
                              position: 'absolute',
                              right: '10px',
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            style={{
                              fontSize: '24px',
                              color: '#666',
                              position: 'absolute',
                              right: '10px',
                            }}
                          />
                        )}
                      </button>

                      <div
                        style={{
                          background: '#FFFFFF',
                          color: '#333',
                          fontSize: '14px',
                          lineHeight: '1.57142857',
                          fontWeight: '400',
                          borderTopRightRadius: '0',
                          borderTopLeftRadius: '0',
                          position: 'relative',
                        }}
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <ul
                          className={`dropdown-menu chatSliderDrowpdownMenu ${
                            isDropdownOpen ? 'show' : ''
                          }`}
                          style={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                            background: '#FFFFFF',
                            color: '#333',
                            padding: '0',
                            boxShadow:
                              '0 1px 5px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.1)', // Set shadow for sides and bottom
                            borderTopRightRadius: '0',
                            borderTopLeftRadius: '0',
                            borderBottomRightRadius: '3px',
                            borderBottomLeftRadius: '3px',
                          }}
                        >
                          <div
                            className=""
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              position: 'sticky',
                              top: '-0px',
                              zIndex: '2',
                              background: '#FFFFFF',
                            }}
                          >
                            <div
                              style={{
                                fontSize: '14px',
                                lineHeight: '1.14285714',
                                fontWeight: '500',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                color: '#484848',
                                padding: '10px 0 8px 10px',
                                borderBottom: '1px solid #e0e0e0',
                                transition: 'background-color 0.3s ease',
                                backgroundColor: hover
                                  ? '#eafcff'
                                  : 'transparent',
                              }}
                              onMouseEnter={() => setHover(true)}
                              onMouseLeave={() => setHover(false)}
                              onClick={() => setDropdownOpen(!isDropdownOpen)}
                            >
                              <span
                                onClick={() => {
                                  allMessages();
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                All internships & jobs
                              </span>{' '}
                              ({countedJobs? countedJobs.length: ""})
                            </div>
                            <div>
                              <p
                                style={{
                                  fontSize: '12px',
                                  marginRight: '10px',
                                  color: '#ACADAC',
                                  fontWeight: '500',
                                  background: '#FFFFFF',
                                  float: 'right',
                                }}
                              >
                                Posted on
                              </p>
                            </div>
                          </div>

                          <div>
                            {countedJobs.map((item, index) => (
                              <div
                                key={index}
                                className="chatSliderDropdownRow"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  padding: '8px 0px 8px 10px',
                                }}
                                onClick={() => setDropdownOpen(!isDropdownOpen)}
                              >
                                <div
                                  style={{ display: 'flex' }}
                                  selected={selectedItem}
                                  onClick={() => handleItemClick(item)}
                                >
                                  <div
                                    style={{
                                      marginRight: '24px',
                                      fontSize: '12px',
                                      // color: '#ACADAC',
                                      color: textHover[index]
                                        ? '#008bdc'
                                        : '#ACADAC',
                                      fontWeight: '500',
                                      width: '60px',
                                      cursor: 'pointer',
                                    }}
                                    onMouseEnter={() =>
                                      handleTextHoverEnter(index)
                                    }
                                    onMouseLeave={() =>
                                      handleTextHoverLeave(index)
                                    }
                                  >
                                    {item.opportunityType}
                                  </div>
                                  <div
                                    style={{
                                      paddingRight: '40px',
                                      fontSize: '12px',
                                      fontWeight: '500',
                                      color: textHover[index]
                                        ? '#008bdc'
                                        : '#ACADAC',
                                      cursor: 'pointer',
                                    }}
                                    onMouseEnter={() =>
                                      handleTextHoverEnter(index)
                                    }
                                    onMouseLeave={() =>
                                      handleTextHoverLeave(index)
                                    }
                                    className="chatSliderDropdownItem"
                                  >
                                    {item.profile}
                                    
                                  </div>
                                </div>
                                <div
                                  style={{
                                    fontSize: '12px',
                                    color: textHover[index]
                                      ? '#008bdc'
                                      : '#ACADAC',
                                    fontWeight: '500',
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                  }}
                                  onMouseEnter={() =>
                                    handleTextHoverEnter(index)
                                  }
                                  onMouseLeave={() =>
                                    handleTextHoverLeave(index)
                                  }
                                  className="chatSliderDropdownItem"
                                >
                                  {item.datePosted.slice(8, 10) +
                                    '/' +
                                    item.datePosted.slice(5, 7) +
                                    '/' +
                                    item.datePosted.slice(0, 4)}
                                </div>
                              </div>
                            ))}
                          </div>
                        </ul>
                      </div>
                    </div>
                          */}
                                    <div className="" style={{ width: "100%" }}>
                                        <div className="" style={{ margin: "0px auto" }}>
                                            <div
                                                className=""
                                                style={{
                                                    fontSize: "28px",
                                                    fontWeight: "500",
                                                    margin: "0px 2rem",
                                                    marginTop: "4px",
                                                }}
                                            >
                                                Messages
                                            </div>

                                            <div
                                                className="sliderSearch"
                                                style={{
                                                    display: "flex",
                                                    border: "1px solid #ddd",
                                                }}
                                            >
                                                <LuSearch
                                                    style={{
                                                        fontSize: "24px",
                                                        color: "#484848",
                                                        fontWeight: "400",
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    value={search}
                                                    onChange={(e) => {
                                                        handleSearch(e);
                                                        setSearch(e.target.value);
                                                    }}
                                                    style={{
                                                        fontSize: "14px",
                                                        lineHeight: "1.14285714",
                                                        fontWeight: "400",
                                                        border: "0",
                                                        outline: "0",
                                                        flex: "1",
                                                        padding: "0 8px",
                                                        height: "20px",
                                                        color: "#484848",
                                                    }}
                                                />

                                                {search && (
                                                    <RxCross1
                                                        height={24}
                                                        width={24}
                                                        fill="#484848"
                                                        fontSize={20}
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            setSearch("");
                                                            handleSearch({ target: { value: "" } });
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={isDropdownOpen ? "chatSliderDropdown1" : ""}></div>

                                    <div className="mb-4"></div>
                                </div>

                                <div className="chats">
                                    {loader ? (
                                        <>
                                            <div className="chats text-center pt-3" style={{ width: "100%" }}>
                                                <div
                                                    className="spinner-border "
                                                    role="status"
                                                    style={{
                                                        width: "27px",
                                                        height: "27px",
                                                        marginTop: "20px",
                                                        color: "#5db2f7",
                                                    }}
                                                ></div>
                                                <p
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "400",
                                                        color: "gray",
                                                    }}
                                                    className=" text-center"
                                                >
                                                    Loading...
                                                </p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {allRooms && allRooms.length > 0 ? (
                                                <>
                                                    {allRooms
                                                        .filter((item) => item.participants[1]?.name?.toLowerCase().includes(search.toLowerCase()))
                                                        .map((item, index) => (
                                                            <div key={index} className="wholeBox">
                                                                <div onClick={() => joinRoom(item.roomId, item.participants[1], item.participants[0], item._id)} key={index} href="#" className={chatActive == item.roomId ? "chat-active" : "chat"}>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            // flexDirection: "row",
                                                                            // alignItems: "center",
                                                                        }}
                                                                    >
                                                                        {item?.participants[1]?.resume?.PersonalDetails?.photo?.url !== "" ? (
                                                                            <div className="">
                                                                                <img
                                                                                    src={item.participants[1]?.resume?.PersonalDetails?.photo?.url}
                                                                                    alt="Profile"
                                                                                    style={{
                                                                                        width: "50px",
                                                                                        height: "50px",
                                                                                        objectFit: "cover",
                                                                                        borderRadius: "50%",
                                                                                        marginRight: "1rem",
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="round">
                                                                                <div className="content">{item?.participants[1]?.name?.charAt(0)}</div>
                                                                            </div>
                                                                        )}
                                                                        <div className="" style={{ width: "100%" }}>
                                                                            <div className="" style={{ display: "flex" }}>
                                                                                <a href={`/previewFreelancer/${item?.participants[1]?.resume?.User}`} target="_blank">
                                                                                    <span
                                                                                        className="title"
                                                                                        style={{
                                                                                            fontWeight: "600",
                                                                                            fontSize: "16px",
                                                                                            lineHeight: "1.25",
                                                                                            textOverflow: "ellipsis",
                                                                                            whiteSpace: "nowrap",
                                                                                            overflow: "hidden",
                                                                                            color: "#333",
                                                                                        }}
                                                                                    >
                                                                                        {item.participants[1]?.name}
                                                                                    </span>
                                                                                </a>
                                                                                <div
                                                                                    style={{
                                                                                        paddingLeft: "24px",
                                                                                        marginLeft: "auto",
                                                                                        display: "flex",
                                                                                        flexShrink: "0",
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "12px",
                                                                                            lineHeight: "1.33333333",
                                                                                            fontWeight: "500",
                                                                                            textAlign: "right",
                                                                                            color: "#8a8a8a",
                                                                                        }}
                                                                                    >
                                                                                        {item.messages && item.messages.length > 0 ? <>{item.messages[item.messages.length - 1].timestamp.slice(8, 10) + "/" + item.messages[item.messages.length - 1].timestamp.slice(5, 7) + "/" + item.messages[item.messages.length - 1].timestamp.slice(0, 4)}</> : <></>}
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <span
                                                                                style={{
                                                                                    fontSize: "14px",
                                                                                    lineHeight: "1.57142857",
                                                                                    fontWeight: "400",
                                                                                    textOverflow: "ellipsis",
                                                                                    whiteSpace: "nowrap",
                                                                                    overflow: "hidden",
                                                                                    color: "#8A8A8A",
                                                                                    padding: "2px 0 0",
                                                                                }}
                                                                            >
                                                                                {item.jobId && item.jobId.profile ? (item.jobId.profile.length > 37 ? `${item.jobId.profile.substring(0, 37)}...` : item.jobId.profile) : ""}
                                                                            </span>

                                                                            <span
                                                                                style={{
                                                                                    fontSize: "14px",
                                                                                    lineHeight: "1.57142857",
                                                                                    // fontWeight: '400',
                                                                                    color: "#666",
                                                                                    display: "flex",
                                                                                }}
                                                                            >
                                                                                {item.messages && item.messages.length > 1 ? (
                                                                                    <>
                                                                                        {item.messages[1].status === "unread" && item.messages[1].receiver === user._id ? (
                                                                                            <>
                                                                                                <p style={{ fontWeight: "400" }}>
                                                                                                    {(() => {
                                                                                                        const senderName = item.messages[1]?.sender === user._id ? "You: " : item?.participants[1]?.name.split(" ")[0] + ": "; // First word from name
                                                                                                        const messageContent = item.messages[1]?.content.replace(/@\[(.*?)\]\(.*?\)/g, "$1") || "";

                                                                                                        const totalLength = senderName.length + messageContent.length;

                                                                                                        if (totalLength > 38) {
                                                                                                            const availableChars = 38 - senderName.length;
                                                                                                            return senderName + messageContent.slice(0, availableChars) + "...";
                                                                                                        }
                                                                                                        return senderName + messageContent;
                                                                                                    })()}
                                                                                                </p>
                                                                                                {readMesCount(item.messages) === 0 ? null : (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            marginLeft: "auto",
                                                                                                            borderRadius: "50px",
                                                                                                            backgroundColor: "#0C5CB7",
                                                                                                            color: "white",
                                                                                                            height: "17px",
                                                                                                            width: "17px",
                                                                                                            fontSize: "10px",
                                                                                                            textAlign: "center",
                                                                                                        }}
                                                                                                    >
                                                                                                        {readMesCount(item.messages)}
                                                                                                    </div>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <p style={{ fontWeight: "400" }}>
                                                                                                    {(() => {
                                                                                                        const senderName = item.messages[1]?.sender === user._id ? "You: " : item?.participants[1]?.name.split(" ")[0] + ": "; // First word from name
                                                                                                        const messageContent = item.messages[1]?.content.replace(/@\[(.*?)\]\(.*?\)/g, "$1") || "";

                                                                                                        const totalLength = senderName.length + messageContent.length;

                                                                                                        if (totalLength > 38) {
                                                                                                            const availableChars = 38 - senderName.length;
                                                                                                            return senderName + messageContent.slice(0, availableChars) + "...";
                                                                                                        }
                                                                                                        return senderName + messageContent;
                                                                                                    })()}
                                                                                                </p>
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <p style={{ fontWeight: "400" }}>...</p>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <button
                                                                        className="btn status"
                                                                        style={{
                                                                            background: "#fffaeb",
                                                                            color: "#333",
                                                                            fontSize: "12px",
                                                                            lineHeight: "1.33333333",
                                                                            fontWeight: "500",
                                                                            borderRadius: "4px",
                                                                            padding: "5px 8px",
                                                                            marginRight: "8px",
                                                                        }}
                                                                    >
                                                                        {item.appliedJobId ? <>{item.appliedJobId.status === "Assignment" ? "Assigment Sent" : item.appliedJobId.status === "Application_Sent" ? "Application Received" : item.appliedJobId.status === "NotIntrested" ? "Not Interested" : item.appliedJobId.status}</> : <>General</>}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </>
                                            ) : (
                                                <>
                                                    <div className="chats text-center" style={{ width: "100%" }}>
                                                        <RiChatOffLine className="mx-auto mt-5" style={{ fontSize: "50px", color: "#5db2f7" }} />
                                                        <p
                                                            style={{
                                                                fontSize: "20px",
                                                                fontWeight: "400",
                                                                color: "gray",
                                                            }}
                                                            className="mt-2 text-center"
                                                        >
                                                            No chat available!!
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>

                            {companyName !== "" && (
                                <div
                                    className="chatroom"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        backgroundColor: "none",
                                    }}
                                >
                                    <div className="sender-area">
                                        <div className="profile ">
                                            <div className="">
                                                <div className="">
                                                    <div className="" style={{ display: "flex", alignItems: "center" }}>
                                                        <a href={`/previewFreelancer/${Id}`} target="_blank">
                                                            {photourlcand ? (
                                                                <img
                                                                    src={photourlcand}
                                                                    alt="Profile"
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        objectFit: "cover",
                                                                        borderRadius: "50%",
                                                                        // border: "2px solid gray",
                                                                        marginRight: "1rem",
                                                                    }}
                                                                // className="phototodisplayindesktop"
                                                                // style={{
                                                                //   boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                                //   border: "4px solid white",
                                                                // }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            objectFit: "cover",
                                                                            borderRadius: "50%",
                                                                            border: "2px solid gray",
                                                                            marginRight: "1rem",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            fontSize: "large",
                                                                            fontWeight: "600",
                                                                        }}
                                                                    >
                                                                        {companyName.charAt(0)}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </a>
                                                        <div
                                                            className=""
                                                        // style={{marginTop: "1rem"}}
                                                        >
                                                            <a href={`/previewFreelancer/${Id}`} target="_blank">
                                                                <strong style={{ fontSize: "large", color: "black" }} onMouseEnter={(e) => (e.target.style.color = "blue")} onMouseLeave={(e) => (e.target.style.color = "black")}>
                                                                    {companyName}&nbsp;
                                                                </strong>
                                                            </a>
                                                            {cand?.city && (
                                                                <div
                                                                    className=""
                                                                    style={{
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                        lineHeight: "1.14285714",
                                                                        color: "#484848",
                                                                        fontSize: "14px",
                                                                        fontWeight: "400",
                                                                        display: "flex",
                                                                        // marginLeft: "6rem",
                                                                        cursor: "default",
                                                                    }}
                                                                >
                                                                    <IoLocation />
                                                                    {cand?.city}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className=""
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginLeft: "1rem",
                                                    color: "#888888",
                                                    marginBottom: "1.5rem",
                                                }}
                                            >
                                                &nbsp;
                                                <FaBagShopping />
                                                &nbsp;
                                                {allRooms.find((item) => item.roomId === chatActive)?.jobId?.profile}
                                            </div>
                                        </div>
                                        <div
                                            className="options"
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                alignItems: "center",
                                            }}
                                        >
                                            {history.appliedJobId && history.appliedJobId.status !== "Hired" ? (
                                                <>
                                                    {hireLoading ? (
                                                        <>
                                                            <button className="statusHire ">
                                                                <div className="spinner-border " role="status" style={{}}></div>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button className="statusHire" onClick={() => handleSelectButtons("Hired")}>
                                                                Hire
                                                            </button>
                                                        </>
                                                    )}

                                                    {notIntrestedLoading ? (
                                                        <>
                                                            <button className="statusNotIntrested ">
                                                                <div
                                                                    className="spinner-border "
                                                                    role="status"
                                                                    style={{
                                                                        width: "13px",
                                                                        height: "13px",
                                                                        marginBottom: "-1px",
                                                                    }}
                                                                ></div>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button className="statusNotIntrested" onClick={() => handleSelectButtons("NotIntrested")}>
                                                                Not Interested
                                                            </button>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {/*   <Dropdown />*/}
                                        </div>
                                    </div>
                                    <div className="message-section">
                                        <div className="conversations" style={{ display: "flex", flexDirection: "column" }}>
                                            <>
                                                {history.messages && history.messages.length > 0 ? (
                                                    <div ref={scroll}>
                                                        {history?.messages?.slice(1)?.map((items, index) => {
                                                            // Convert timestamps to Date objects
                                                            const currentDate = new Date(items.timestamp);
                                                            // Check if the date has changed
                                                            const showTimestamp = !lastDisplayedDate || lastDisplayedDate.getFullYear() !== currentDate.getFullYear() || lastDisplayedDate.getMonth() !== currentDate.getMonth() || lastDisplayedDate.getDate() !== currentDate.getDate();
                                                            // Calculate time difference between current and previous messages (in minutes)
                                                            const timeDifferenceInMinutes = index > 0 ? (new Date(items.timestamp) - new Date(history?.messages[index - 1]?.timestamp)) / (1000 * 60) : Number.MAX_VALUE; // Use large number for the first message

                                                            // Show name and image if either different sender or time difference is greater than 2 minutes
                                                            const showNameAndImage = index === 0 || history?.messages[index - 1].sender !== items.sender || timeDifferenceInMinutes > 2;
                                                            // setEditStatus(items?.edited)

                                                            return (
                                                                <div key={index}>
                                                                    <div className="">
                                                                        <div className="">
                                                                            {/* Display timestamp only if the date has changed */}
                                                                            {index > 0 ? (
                                                                                new Date(history.messages[index - 1].timestamp).toLocaleDateString() !== new Date(items.timestamp).toLocaleDateString() && (
                                                                                    <div
                                                                                        className="timestamp text-center"
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            padding: "10px 0",
                                                                                            textAlign: "center",
                                                                                            clear: "both",
                                                                                            display: "block",
                                                                                        }}
                                                                                    >
                                                                                        {isToday(new Date(items.timestamp)) ? (
                                                                                            <p
                                                                                                style={{
                                                                                                    fontSize: "14px",
                                                                                                    padding: "10px 0",
                                                                                                    textAlign: "center",
                                                                                                    clear: "both",
                                                                                                }}
                                                                                            >
                                                                                                Today
                                                                                            </p>
                                                                                        ) : (
                                                                                            <p
                                                                                                style={{
                                                                                                    fontSize: "14px",
                                                                                                    padding: "10px 0",
                                                                                                    textAlign: "center",
                                                                                                    clear: "both",
                                                                                                }}
                                                                                            >
                                                                                                {new Date(items.timestamp).toLocaleDateString("en-GB", {
                                                                                                    day: "2-digit",
                                                                                                    month: "long",
                                                                                                })}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                )
                                                                            ) : (
                                                                                <div
                                                                                    className="timestamp text-center"
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        padding: "10px 0",
                                                                                        textAlign: "center",
                                                                                        clear: "both",
                                                                                        display: "block",
                                                                                    }}
                                                                                >
                                                                                    {isToday(new Date(items.timestamp)) ? (
                                                                                        "Today"
                                                                                    ) : (
                                                                                        <div
                                                                                            style={{
                                                                                                fontSize: "14px",
                                                                                                padding: "10px 0",
                                                                                                textAlign: "center",
                                                                                                clear: "both",
                                                                                            }}
                                                                                        >
                                                                                            {new Date(items.timestamp).toLocaleDateString("en-GB", {
                                                                                                day: "2-digit",
                                                                                                month: "long",
                                                                                            })}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    // alignItems: "center",
                                                                                    marginTop: showNameAndImage ? "1rem" : "0px",
                                                                                }}
                                                                            >
                                                                                {showNameAndImage &&
                                                                                    ((items.sender !== user._id ? photourlcand : photourlclnt) ? (
                                                                                        <img
                                                                                            src={items.sender !== user._id ? photourlcand : photourlclnt}
                                                                                            alt="Profile"
                                                                                            style={{
                                                                                                width: "50px",
                                                                                                height: "50px",
                                                                                                objectFit: "cover",
                                                                                                borderRadius: "50%",
                                                                                                // border: "2px solid gray",
                                                                                                marginRight: "1rem",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className="">
                                                                                                <div
                                                                                                    style={{
                                                                                                        width: "50px",
                                                                                                        height: "50px",
                                                                                                        objectFit: "cover",
                                                                                                        borderRadius: "50%",
                                                                                                        border: "2px solid gray",
                                                                                                        marginRight: "1rem",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "center",
                                                                                                        alignItems: "center",
                                                                                                        fontSize: "large",
                                                                                                        fontWeight: "600",
                                                                                                    }}
                                                                                                >
                                                                                                    {(items.sender === user._id ? candidatename : companyName).charAt(0)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ))}
                                                                                <div style={{ width: "100%", backgroundColor: "none" }}>
                                                                                    {showNameAndImage && (
                                                                                        <div
                                                                                            className=""
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className=""
                                                                                                style={{
                                                                                                    fontWeight: "500",
                                                                                                    padding: "5px",
                                                                                                }}
                                                                                            >
                                                                                                {/* Display your name when you send a message */}
                                                                                                {items.sender === user._id ? candidatename : companyName}
                                                                                            </div>
                                                                                            &nbsp; &nbsp; &nbsp;
                                                                                            {items.sender === user._id ? (
                                                                                                <div style={{ color: "#888", fontSize: "0.8em", }} >
                                                                                                    {getTime(items.timestamp).toUpperCase()}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div style={{ color: "#888", fontSize: "0.8em", }}>
                                                                                                    {getTime(items.timestamp).toUpperCase()}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                    <div className={` ${items.sender === user._id ? "right" : "left"}`} style={{ maxWidth: "100%", backgroundColor: "none" }}>
                                                                                        {/* <p>{items.content}</p> */}

                                                                                        <div style={{ padding: "0px 5px", overflowWrap: "break-word", wordWrap: "break-word", wordBreak: "break-word", hyphens: "auto", fontSize: "14px", lineHeight: "1.5", marginLeft: showNameAndImage ? "0" : "6rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                                                                            {items.replyTo === null ? (<span>
                                                                                                <span dangerouslySetInnerHTML={{ __html: wrapLinks((items.content || "").trim().replace(/@\[(.*?)\]\(.*?\)/g, "$1").replace(/\n/g, "<br />")), }} ></span>
                                                                                                <span style={{ marginLeft: "5px", fontSize: "10px" }}>{items.edited && "(Edited)"}</span>
                                                                                            </span>
                                                                                            ) : (
                                                                                                <div className="replyMsgBox">
                                                                                                    <div className="innerReplyMsgBox">
                                                                                                        <img src={replyArrow} alt="" className="replyIcon" />
                                                                                                        {(() => {
                                                                                                            // Find the replied message
                                                                                                            const conversation = history?.messages; // Assuming `history` contains the current conversation
                                                                                                            const replyMsg = items?.replyTo;

                                                                                                            const repliedMessage = conversation?.find((msg) => msg._id === replyMsg);

                                                                                                            if (!repliedMessage) {
                                                                                                                return (
                                                                                                                    <span style={{ padding: "0px 5px", fontSize: "14px" }}>
                                                                                                                        Message not found
                                                                                                                    </span>
                                                                                                                );
                                                                                                            }

                                                                                                            // Determine the name to display
                                                                                                            const nameToDisplay = repliedMessage.sender === user?._id ? user?.name : receriverData?.name;

                                                                                                            return (
                                                                                                                <>
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            padding: "0px 5px",
                                                                                                                            overflowWrap: "break-word",
                                                                                                                            wordWrap: "break-word",
                                                                                                                            wordBreak: "break-word",
                                                                                                                            hyphens: "auto",
                                                                                                                            fontSize: "14px",
                                                                                                                            lineHeight: "1.5",
                                                                                                                            display: "flex",
                                                                                                                            justifyContent: "space-between",
                                                                                                                            alignItems: "center",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {/* Display the replied message */}
                                                                                                                        <p>{repliedMessage?.content}</p>
                                                                                                                    </div>
                                                                                                                    {/* Display sender/receiver name and timestamp */}
                                                                                                                    <span style={{ fontSize: "14px", padding: "0px 5px" }}>
                                                                                                                        {nameToDisplay},{" "}
                                                                                                                        {new Date(repliedMessage.timestamp).toLocaleString("en-US", {
                                                                                                                            weekday: "short",
                                                                                                                            hour: "2-digit",
                                                                                                                            minute: "2-digit",
                                                                                                                        })}
                                                                                                                    </span>
                                                                                                                </>
                                                                                                            );
                                                                                                        })()}
                                                                                                    </div>
                                                                                                    {items?.content} <span style={{ marginLeft: "5px", fontSize: "10px" }}>{items.edited && "(Edited)"}</span>


                                                                                                </div>

                                                                                            )}


                                                                                            <MessageOptionModal senderId={items.sender} conversationId={conversationId} messageId={items._id} handleRemoveMessage={handleRemoveMessage} allData={items} wholeData={history} handleEditMessage={handleEditMessage} setEditFlag={setEditFlag} setchatMessage={setchatMessage} setEditMessageId={setEditMessageId} setEditConversationId={setEditConversationId} setDeleteMessage={setDeleteMessage} setReplyMessage={setReplyMessage} setDeleteFlag={setDeleteFlag} setReplyFlag={setReplyFlag} user={user} />

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        </div>

                                        <div className="send-message-box" >
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", borderRadius: "3px", maxHeight: "250px", overflowY: "auto", gap: "1rem" }} >
                                                {/* display the replied msg box   */}
                                                <div className="replyMsgBox" style={{ display: replyFlag ? "block" : "none" }}>
                                                    {replyMessage?.conversationId && replyMessage?.parentMessageId ? (
                                                        <div className="innerReplyMsgBox">
                                                            <img src={replyArrow} alt="" className="replyIcon" />
                                                            <div className="cross" onClick={() => { setReplyFlag(false), setchatMessage(""), setReplyFlag(false) }}>
                                                                <img
                                                                    src={cross}
                                                                    alt=""
                                                                    className="crossIcon"
                                                                />
                                                            </div>
                                                            {(() => {
                                                                // Find the replied message
                                                                const conversation = history?.messages; // Assuming `history` contains the current conversation
                                                                const repliedMessage = conversation?.find((msg) => msg._id === replyMessage.parentMessageId);
                                                                console.log(repliedMessage);

                                                                if (!repliedMessage) {
                                                                    return <span style={{ padding: "0px 5px", fontSize: "14px" }}>Message not found</span>;
                                                                }

                                                                return (
                                                                    <>
                                                                        <div
                                                                            style={{
                                                                                padding: "0px 5px",
                                                                                overflowWrap: "break-word",
                                                                                wordWrap: "break-word",
                                                                                wordBreak: "break-word",
                                                                                hyphens: "auto",
                                                                                fontSize: "14px",
                                                                                lineHeight: "1.5",
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            {/* Display the replied message */}
                                                                            <span
                                                                                style={{ fontSize: "16px" }}
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: wrapLinks(
                                                                                        (repliedMessage.content || "")
                                                                                            .trim()
                                                                                            .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                            .replace(/\n/g, "<br />")
                                                                                    ),
                                                                                }}
                                                                            ></span>
                                                                        </div>
                                                                        {/* Display sender name and timestamp */}
                                                                        <span style={{ fontSize: "14px", padding: "0px 5px" }}>
                                                                            {repliedMessage.sender === user?._id ? user?.name : receriverData?.name},{" "}{" "}
                                                                            {new Date(repliedMessage.timestamp).toLocaleString("en-US", {
                                                                                weekday: "short",
                                                                                hour: "2-digit",
                                                                                minute: "2-digit",
                                                                            })}
                                                                        </span>
                                                                    </>
                                                                );
                                                            })()}
                                                        </div>
                                                    ) : (
                                                        <span style={{ padding: "0px 5px", fontSize: "14px" }}>No reply message selected</span>
                                                    )}
                                                </div>


                                                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
                                                    <textarea ref={textareaRef} value={chatMessage} onChange={handleChange} onKeyDown={handleKeyDown} placeholder="Write a message...."
                                                        style={{
                                                            fontSize: "15px", lineHeight: "25px", height: "45px",
                                                            padding: "8px 31px 5px 11px", width: "100%",
                                                            outline: "none",
                                                            borderRadius: "5px",
                                                            border: "solid 1px #d6d6d6",
                                                            resize: "none",
                                                            overflowY: "auto",
                                                            maxHeight: "250px",
                                                            // backgroundColor: 'blue'
                                                        }}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (editFlag) {
                                                                // Editing an existing message
                                                                handleEditMessage(chatMessage, editConversationId, editMessageId);
                                                                setEditFlag(false);
                                                                setchatMessage("");
                                                            } else if (replyFlag) {

                                                                handleReplyMessage(chatMessage, replyMessage?.conversationId, replyMessage?.parentMessageId);
                                                                setReplyFlag(false);
                                                                setchatMessage("");
                                                            } else {
                                                                // Sending a new message
                                                                const recipientId =
                                                                    history?.participants[0] === user?._id
                                                                        ? history?.participants[1]
                                                                        : history?.participants[0];

                                                                sendMessage(recipientId, history?.jobId);
                                                                setchatMessage("");
                                                            }
                                                        }}
                                                        className="btn btnsend"
                                                        style={{
                                                            backgroundColor: chatMessage?.length > 0 ? "#5db2f7" : "#bbb",
                                                        }}
                                                        disabled={chatMessage?.length === 0}
                                                    >
                                                        {editFlag ? "Edit" : replyFlag ? "Reply" : "Send"}  </button>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </div>

                {/* Model 1: Send Assigment */}
                <div className="modal fade" id="sendassignment" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" style={{ borderRadius: "20px" }}>
                        <div className="modal-content PopupBox" style={{ borderRadius: "20px" }}>
                            <div className="modal-body" style={{ borderRadius: "20px" }}>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div>
                                        <p
                                            style={{
                                                fontSize: "38px",
                                                fontWeight: "500",
                                                color: "#444444",
                                            }}
                                        >
                                            {" "}
                                            <img
                                                src={sendAssignmentLogo}
                                                alt=".."
                                                style={{
                                                    width: "38px",
                                                    height: "35px",
                                                    marginRight: "8px",
                                                }}
                                            />{" "}
                                            Send Assignment
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: "30px",
                                            marginLeft: "auto",
                                            marginTop: "9px",
                                        }}
                                    >
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                                <p
                                    style={{
                                        fontSize: "23px",
                                        fontWeight: "700",
                                        marginTop: "5px",
                                    }}
                                >
                                    To: Anil Bansal
                                </p>
                                <p
                                    style={{
                                        fontSize: "23px",
                                        fontWeight: "500",
                                        marginTop: "15px",
                                    }}
                                >
                                    Add Description
                                </p>
                                <textarea
                                    rows={8}
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        padding: "10px",
                                    }}
                                    name="description"
                                    value={inputDataSendAssigment.description}
                                    onChange={handleInputSendAssigment}
                                ></textarea>
                                <div
                                    className="py-2 "
                                    style={{
                                        color: "black",
                                        marginTop: "22px",
                                        backgroundColor: "#D6EAF8",
                                        width: "300px",
                                        paddingLeft: "30px",
                                        borderRadius: "8px",
                                        fontSize: "20px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Add Attachment
                                </div>
                                <p
                                    style={{
                                        marginTop: "10px",
                                        color: "black",
                                        fontWeight: "400",
                                    }}
                                >
                                    File Limit 5MB, Upload pdf,png,zip,gif,jpg,jpeg
                                </p>
                                <p
                                    style={{
                                        fontSize: "23px",
                                        fontWeight: "500",
                                        marginTop: "20px",
                                    }}
                                >
                                    Select Submission Date
                                </p>
                                <input
                                    className="px-5 py-4"
                                    type="date"
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        fontSize: "18px",
                                    }}
                                    name="date"
                                    value={inputDataSendAssigment.date}
                                    onChange={handleInputSendAssigment}
                                />
                                <div className="text-end">
                                    <button className="sendAssignmentBtn" onClick={handleSubmitSendAssigment}>
                                        Send Assignment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Model 2: Send Report */}
                <div className="modal fade" id="sendreport" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" style={{ borderRadius: "20px" }}>
                        <div className="modal-content PopupBox" style={{ borderRadius: "20px" }}>
                            <div className="modal-body my-4" style={{ borderRadius: "20px" }}>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div>
                                        <p
                                            style={{
                                                fontSize: "36px",
                                                fontWeight: "500",
                                                color: "#444444",
                                            }}
                                        >
                                            {" "}
                                            <img
                                                src={reportLogo}
                                                alt=".."
                                                style={{
                                                    width: "38px",
                                                    height: "40px",
                                                    marginRight: "8px",
                                                }}
                                            />{" "}
                                            Report Applicant
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: "30px",
                                            marginLeft: "auto",
                                            marginTop: "9px",
                                        }}
                                    >
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>

                                <p
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: "500",
                                        marginTop: "25px",
                                    }}
                                >
                                    Select the reason to report applicant
                                </p>
                                <div className="dropdown">
                                    <button
                                        className="btn chatSliderDropdown2 dropdown-toggle pr-4 "
                                        type="button"
                                        id="dropdownMenuButton2"
                                        data-bs-toggle="dropdown"
                                        ref={dropdownRef1}
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            width: "100%",
                                            borderRadius: "10px",
                                            fontSize: "20px",
                                            marginTop: "5px",
                                            border: "solid 1px black",
                                            color: "white",
                                            paddingLeft: "20px",
                                            paddingTop: "20px",
                                            paddingBottom: "20px",
                                        }}
                                        onClick={handleDropdownToggle1}
                                    >
                                        <span
                                            style={{
                                                color: "black",
                                                textAlign: "start",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {inputDataSendReport && inputDataSendReport.reason ? inputDataSendReport.reason.slice(0, 24) : ""}
                                        </span>
                                        {isDropdownOpen1 || isButtonClicked1 ? (
                                            <IoIosArrowUp
                                                style={{
                                                    fontSize: "26px",
                                                    color: "black",
                                                    marginLeft: "auto",
                                                    marginRight: "-5px",
                                                    marginTop: "-2px",
                                                }}
                                            />
                                        ) : (
                                            <IoIosArrowDown
                                                style={{
                                                    fontSize: "26px",
                                                    color: "black",
                                                    marginLeft: "auto",
                                                    marginRight: "-5px",
                                                    marginTop: "-2px",
                                                }}
                                            />
                                        )}
                                    </button>

                                    <ul
                                        className={`dropdown-menu chatSliderDrowpdownMenu1 ${isDropdownOpen1 ? "show" : ""}`}
                                        aria-labelledby="dropdownMenuButton2"
                                        style={{
                                            width: "100%",
                                            borderRadius: "10px",
                                            border: "none",
                                            paddingtop: "10px",
                                            paddingBottom: "10px",
                                            paddingLeft: "30px",
                                            paddingRight: " 40px",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                            backgroundColor: "#D6EAF8",
                                        }}
                                    >
                                        {reason.map((item, index) => (
                                            <li
                                                key={index}
                                                className={` dropdown-item  ${inputDataSendReport.reason === item ? "selected" : ""}`}
                                                style={{
                                                    fontWeight: "500",
                                                    // padding: '10px',
                                                    paddingLeft: "20px",
                                                    marginTop: "5px",
                                                    marginBottom: "5px",
                                                    cursor: "pointer",
                                                    borderRadius: "10px",
                                                }}
                                                onClick={() => handleDropdownItemClick1(item)}
                                            >
                                                {item.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {inputDataSendReport.reason == "Others" ? (
                                    <>
                                        <p
                                            style={{
                                                fontSize: "22px",
                                                fontWeight: "500",
                                                marginTop: "30px",
                                            }}
                                        >
                                            Mention the reason
                                        </p>
                                        <input
                                            className=""
                                            type="text"
                                            style={{
                                                width: "100%",
                                                borderRadius: "10px",
                                                fontSize: "18px",
                                                marginTop: "5px",
                                                padding: "20px",
                                            }}
                                            name="otherReason"
                                            value={inputDataSendReport.otherReason}
                                            onChange={handleInputSendReport}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                                <p
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: "500",
                                        marginTop: "30px",
                                    }}
                                >
                                    Description
                                </p>
                                <textarea
                                    rows={5}
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        padding: "10px",
                                    }}
                                    name="description"
                                    value={inputDataSendReport.description}
                                    onChange={handleInputSendReport}
                                />

                                <div className="text-end">
                                    <button className="sendAssignmentBtn mt-4" onClick={handleSubmitSendReport}>
                                        Report Applicant
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DeleteMessageModal open={open} setOpen={setOpen} setDeleteConfirm={setDeleteConfirm} />

            {/*********************MOBILE VIEW*********************/}

            <div className="ChatMobView">
                <div
                    className="message-student pb-0"
                    style={{
                        backgroundColor: "#F8F8F8",
                        padding: "40px 10px",
                        maxWidth: "1200px",
                        margin: "0 auto",
                    }}
                >
                    <div className="chat-box1">
                        <>
                            {viewMessage ? (
                                <>
                                    {companyName !== "" && (
                                        <div
                                            className="chatroom"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div style={{ backgroundColor: "white", marginBottom: "-10px", color: "#0077e4", cursor: "pointer", }}onClick={() => setViewMesage(false)} >
                                                <p className="mt-1  ml-3" style={{ fontSize: "14px" }}>
                                                    {" "}
                                                    <MdKeyboardBackspace className="ml-1 mb-1" style={{ fontSize: "15px" }} /> Back
                                                </p>
                                            </div>
                                            <div className="sender-area">
                                                <div className="row">
                                                    <div className="profile ">
                                                        <div className="">
                                                            <a
                                                                href={`/previewFreelancer/${Id}`}
                                                                target="_blank"
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {photourlcand ? (
                                                                    <img
                                                                        src={photourlcand}
                                                                        alt="Profile"
                                                                        style={{
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            objectFit: "cover",
                                                                            borderRadius: "50%",
                                                                            // border: "2px solid gray",
                                                                            marginRight: "1rem",
                                                                        }}
                                                                    // className="phototodisplayindesktop"
                                                                    // style={{
                                                                    //   boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                                    //   border: "4px solid white",
                                                                    // }}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <div
                                                                            style={{
                                                                                width: "50px",
                                                                                height: "50px",
                                                                                objectFit: "cover",
                                                                                borderRadius: "50%",
                                                                                border: "2px solid gray",
                                                                                marginRight: "1rem",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                fontSize: "large",
                                                                                fontWeight: "600",
                                                                            }}
                                                                        >
                                                                            {companyName.charAt(0)}
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <div
                                                                    className=""
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <strong>{companyName}&nbsp;</strong>
                                                                    <div
                                                                        className=""
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            // marginLeft: "1rem",
                                                                            color: "#888888",
                                                                        }}
                                                                    >
                                                                        &nbsp;
                                                                        <FaBagShopping />
                                                                        &nbsp;
                                                                        {cand?.resume?.PersonalDetails?.profile}
                                                                        {/* {allRooms
                                                                            .find(
                                                                                (item) => item.roomId === chatActive
                                                                            )
                                                                            ?.jobId?.profile.slice(0, 20) + "..."} */}
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        {/* {history.appliedJobId ? (
                        <>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openReviewPageInNewTab(
                                allRooms.find(
                                  (item) => item.roomId === chatActive
                                )
                              )
                            }
                          >
                            {" | View Application "}
                            <FiExternalLink />
                          </div>
                        </>
                      ) : (
                        <></>
                      )} */}
                                                    </div>
                                                    <div className="col">
                                                        <div
                                                            className="options"
                                                            style={{
                                                                display: "flex",
                                                                flexWrap: "wrap",
                                                                alignItems: "center",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            {history.appliedJobId ? (
                                                                <>
                                                                    {hireLoading ? (
                                                                        <>
                                                                            <button className="statusHire ">
                                                                                <div className="spinner-border " role="status" style={{}}></div>
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button className="statusHire" onClick={() => handleSelectButtons("Hired")}>
                                                                                Hire
                                                                            </button>
                                                                        </>
                                                                    )}

                                                                    {notIntrestedLoading ? (
                                                                        <>
                                                                            <button className="statusNotIntrested ">
                                                                                <div
                                                                                    className="spinner-border "
                                                                                    role="status"
                                                                                    style={{
                                                                                        width: "13px",
                                                                                        height: "13px",
                                                                                        marginBottom: "-1px",
                                                                                    }}
                                                                                ></div>
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button className="statusNotIntrested" onClick={() => handleSelectButtons("NotIntrested")}>
                                                                                Not Interested
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="message-section">
                                                <div className="conversations">
                                                    <>
                                                        {history.messages && history.messages.length > 0 ? (
                                                            <div ref={scroll}>
                                                                {history?.messages?.slice(1)?.map((items, index) => {
                                                                    // Convert timestamps to Date objects
                                                                    // Convert timestamps to Date objects
                                                                    const currentDate = new Date(items.timestamp);
                                                                    // Check if the date has changed
                                                                    const showTimestamp = !lastDisplayedDate || lastDisplayedDate.getFullYear() !== currentDate.getFullYear() || lastDisplayedDate.getMonth() !== currentDate.getMonth() || lastDisplayedDate.getDate() !== currentDate.getDate();
                                                                    // Calculate time difference between current and previous messages (in minutes)
                                                                    const timeDifferenceInMinutes = index > 0 ? (new Date(items.timestamp) - new Date(history?.messages[index - 1]?.timestamp)) / (1000 * 60) : Number.MAX_VALUE; // Use large number for the first message

                                                                    // Show name and image if either different sender or time difference is greater than 2 minutes
                                                                    const showNameAndImage = index === 0 || history?.messages[index - 1].sender !== items.sender || timeDifferenceInMinutes > 2;
                                                                    // setEditStatus(items?.edited)

                                                                    return (
                                                                        <div key={index}>
                                                                            <div className="">
                                                                                <div className="">
                                                                                    {/* Display timestamp only if the date has changed */}
                                                                                    {index > 0 ? (
                                                                                        new Date(history.messages[index - 1].timestamp).toLocaleDateString() !== new Date(items.timestamp).toLocaleDateString() && (
                                                                                            <div
                                                                                                className="timestamp text-center"
                                                                                                style={{
                                                                                                    fontSize: "14px",
                                                                                                    padding: "10px 0",
                                                                                                    textAlign: "center",
                                                                                                    clear: "both",
                                                                                                    display: "block",
                                                                                                }}
                                                                                            >
                                                                                                {isToday(new Date(items.timestamp)) ? (
                                                                                                    <p
                                                                                                        style={{
                                                                                                            fontSize: "14px",
                                                                                                            padding: "10px 0",
                                                                                                            textAlign: "center",
                                                                                                            clear: "both",
                                                                                                        }}
                                                                                                    >
                                                                                                        Today
                                                                                                    </p>
                                                                                                ) : (
                                                                                                    <p
                                                                                                        style={{
                                                                                                            fontSize: "14px",
                                                                                                            padding: "10px 0",
                                                                                                            textAlign: "center",
                                                                                                            clear: "both",
                                                                                                        }}
                                                                                                    >
                                                                                                        {new Date(items.timestamp).toLocaleDateString("en-GB", {
                                                                                                            day: "2-digit",
                                                                                                            month: "long",
                                                                                                        })}
                                                                                                    </p>
                                                                                                )}
                                                                                            </div>
                                                                                        )
                                                                                    ) : (
                                                                                        <div
                                                                                            className="timestamp text-center"
                                                                                            style={{
                                                                                                fontSize: "14px",
                                                                                                padding: "10px 0",
                                                                                                textAlign: "center",
                                                                                                clear: "both",
                                                                                                display: "block",
                                                                                            }}
                                                                                        >
                                                                                            {isToday(new Date(items.timestamp)) ? (
                                                                                                "Today"
                                                                                            ) : (
                                                                                                <div
                                                                                                    style={{
                                                                                                        fontSize: "14px",
                                                                                                        padding: "10px 0",
                                                                                                        textAlign: "center",
                                                                                                        clear: "both",
                                                                                                    }}
                                                                                                >
                                                                                                    {new Date(items.timestamp).toLocaleDateString("en-GB", {
                                                                                                        day: "2-digit",
                                                                                                        month: "long",
                                                                                                    })}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            // alignItems: "center",
                                                                                            marginTop: showNameAndImage ? "1rem" : "0px",
                                                                                        }}
                                                                                    >
                                                                                        {showNameAndImage &&
                                                                                            ((items.sender !== user._id ? photourlcand : photourlclnt) ? (
                                                                                                <img
                                                                                                    src={items.sender !== user._id ? photourlcand : photourlclnt}
                                                                                                    alt="Profile"
                                                                                                    style={{
                                                                                                        width: "50px",
                                                                                                        height: "50px",
                                                                                                        objectFit: "cover",
                                                                                                        borderRadius: "50%",
                                                                                                        // border: "2px solid gray",
                                                                                                        marginRight: "1rem",
                                                                                                    }}
                                                                                                // className="phototodisplayindesktop"
                                                                                                // style={{
                                                                                                //   boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                                                                //   border: "4px solid white",
                                                                                                // }}
                                                                                                />
                                                                                            ) : (
                                                                                                <>
                                                                                                    <div className="" >
                                                                                                        <div
                                                                                                            style={{
                                                                                                                width: "50px",
                                                                                                                height: "50px",
                                                                                                                objectFit: "cover",
                                                                                                                borderRadius: "50%",
                                                                                                                border: "2px solid gray",
                                                                                                                marginRight: "1rem",
                                                                                                                display: "flex",
                                                                                                                justifyContent: "center",
                                                                                                                alignItems: "center",
                                                                                                                fontSize: "large",
                                                                                                                fontWeight: "600",
                                                                                                            }}
                                                                                                        >
                                                                                                            {(items.sender === user._id ? candidatename : companyName).charAt(0)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            ))}
                                                                                        <div style={{ width: "100%", padding: "2px 0px" }}>
                                                                                            {showNameAndImage && (
                                                                                                <div
                                                                                                    className=""
                                                                                                    style={{
                                                                                                        display: "flex",
                                                                                                        alignItems: "center",
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className=""
                                                                                                        style={{
                                                                                                            fontWeight: "500",
                                                                                                            padding: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        {items.sender === user._id ? candidatename : companyName}
                                                                                                    </div>
                                                                                                    &nbsp; &nbsp; &nbsp;
                                                                                                    {items.sender === user._id ? (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                color: "#888",
                                                                                                                fontSize: "0.8em",
                                                                                                            }}
                                                                                                        >
                                                                                                            {getTime(items.timestamp).toUpperCase()}
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                color: "#888",
                                                                                                                fontSize: "0.8em",
                                                                                                            }}
                                                                                                        >
                                                                                                            {getTime(items.timestamp).toUpperCase()}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                            <div style={{ padding: "0px 5px", overflowWrap: "break-word", wordWrap: "break-word", wordBreak: "break-word", hyphens: "auto", fontSize: "14px", lineHeight: "1.5", marginLeft: showNameAndImage ? "0" : "6rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                {items.replyTo === null ? (
                                                                                                    <span>
                                                                                                        <span
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: wrapLinks(
                                                                                                                    (items.content || "")
                                                                                                                        .trim()
                                                                                                                        .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                                        .replace(/\n/g, "<br />")
                                                                                                                ),
                                                                                                            }}
                                                                                                        ></span>
                                                                                                        <span style={{ marginLeft: "5px", fontSize: "10px" }}>{items.edited && "(Edited)"}</span>
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <div className="replyMsgBox">
                                                                                                    <div className="innerReplyMsgBox">
                                                                                                        <img src={replyArrow} alt="" className="replyIcon" />
                                                                                                        {(() => {
                                                                                                            // Find the replied message
                                                                                                            const conversation = history?.messages; // Assuming `history` contains the current conversation
                                                                                                            const replyMsg = items?.replyTo;

                                                                                                            const repliedMessage = conversation?.find((msg) => msg._id === replyMsg);

                                                                                                            if (!repliedMessage) {
                                                                                                                return (
                                                                                                                    <span style={{ padding: "0px 5px", fontSize: "14px" }}>
                                                                                                                        Message not found
                                                                                                                    </span>
                                                                                                                );
                                                                                                            }

                                                                                                            // Determine the name to display
                                                                                                            const receriverNameForPhone = allRooms.find((data) => data.roomId === conversationId)?.participants[1];
                                                                                                            const nameToDisplay = repliedMessage.sender === user?._id ? user?.name : (receriverData?.name ? receriverData?.name : receriverNameForPhone?.name);

                                                                                                            return (
                                                                                                                <>
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            padding: "0px 5px",
                                                                                                                            overflowWrap: "break-word",
                                                                                                                            wordWrap: "break-word",
                                                                                                                            wordBreak: "break-word",
                                                                                                                            hyphens: "auto",
                                                                                                                            fontSize: "14px",
                                                                                                                            lineHeight: "1.5",
                                                                                                                            display: "flex",
                                                                                                                            justifyContent: "space-between",
                                                                                                                            alignItems: "center",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {/* Display the replied message */}
                                                                                                                        <p>{repliedMessage?.content}</p>
                                                                                                                    </div>
                                                                                                                    {/* Display sender/receiver name and timestamp */}
                                                                                                                    <span style={{ fontSize: "14px", padding: "0px 5px" }}>
                                                                                                                        {nameToDisplay},{" "}
                                                                                                                        {new Date(repliedMessage.timestamp).toLocaleString("en-US", {
                                                                                                                            weekday: "short",
                                                                                                                            hour: "2-digit",
                                                                                                                            minute: "2-digit",
                                                                                                                        })}
                                                                                                                    </span>
                                                                                                                </>
                                                                                                            );
                                                                                                        })()}
                                                                                                    </div>
                                                                                                    {items?.content} <span style={{ marginLeft: "5px", fontSize: "10px" }}>{items.edited && "(Edited)"}</span>


                                                                                                </div>
                                                                                                )}

                                                                                                <MessageOptionModal senderId={items.sender} conversationId={conversationId} messageId={items._id} handleRemoveMessage={handleRemoveMessage} allData={items} wholeData={history} handleEditMessage={handleEditMessage} setEditFlag={setEditFlag} setchatMessage={setchatMessage} setEditMessageId={setEditMessageId} setEditConversationId={setEditConversationId} setDeleteMessage={setDeleteMessage} setReplyMessage={setReplyMessage} setDeleteFlag={setDeleteFlag} setReplyFlag={setReplyFlag} user={user} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                </div>

                                                <div className="send-message-box" style={{ maxHeight: "250px" }}>

                                                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", borderRadius: "3px", paddingRight: "10px", maxHeight: "250px", overflowY: "auto", }}
                                                    >
                                                        <div className="replyMsgBox" style={{ display: replyFlag ? "block" : "none" }}>
                                                            {replyMessage?.conversationId && replyMessage?.parentMessageId ? (
                                                                <div className="innerReplyMsgBox">
                                                                    <img src={replyArrow} alt="" className="replyIcon" />
                                                                    <div
                                                                        className="cross"
                                                                        onClick={() => {
                                                                            setReplyFlag(false), setchatMessage(""), setReplyFlag(false);
                                                                        }}
                                                                    >
                                                                        <img src={cross} alt="" className="crossIcon" />
                                                                    </div>
                                                                    {(() => {
                                                                        // Find the replied message
                                                                        const conversation = history?.messages; // Assuming `history` contains the current conversation
                                                                        const repliedMessage = conversation?.find((msg) => msg._id === replyMessage.parentMessageId);
                                                                        console.log("replied msg", repliedMessage);
                                                                        const receriverNameForPhone = allRooms.find((data) => data.roomId === conversationId)?.participants[1];
                                                                        console.log(receriverNameForPhone);
                                                                        console.log(conversationId);
                                                                        
                                                                        // const newData = allRooms.find((data) => data?._id )
                                                                        if (!repliedMessage) {
                                                                            return <span style={{ padding: "0px 5px", fontSize: "14px" }}>Message not found</span>;
                                                                        }
                                                                        console.log(user?.name);

                                                                        return (
                                                                            <>
                                                                                <div
                                                                                    style={{
                                                                                        padding: "0px 5px",
                                                                                        overflowWrap: "break-word",
                                                                                        wordWrap: "break-word",
                                                                                        wordBreak: "break-word",
                                                                                        hyphens: "auto",
                                                                                        fontSize: "14px",
                                                                                        lineHeight: "1.5",
                                                                                        display: "flex",
                                                                                        justifyContent: "space-between",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    {/* Display the replied message */}
                                                                                    <span
                                                                                        style={{ fontSize: "16px" }}
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: wrapLinks(
                                                                                                (repliedMessage.content || "")
                                                                                                    .trim()
                                                                                                    .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                    .replace(/\n/g, "<br />")
                                                                                            ),
                                                                                        }}
                                                                                    ></span>
                                                                                </div>
                                                                                {/* Display sender name and timestamp */}
                                                                                <span style={{ fontSize: "14px", padding: "0px 5px" }}>
                                                                                    {repliedMessage.sender === user?._id ? user?.name : (receriverData?.name ? receriverData?.name : receriverNameForPhone?.name)},{" "}
                                                                                    {new Date(repliedMessage.timestamp).toLocaleString("en-US", {
                                                                                        weekday: "short",
                                                                                        hour: "2-digit",
                                                                                        minute: "2-digit",
                                                                                    })}
                                                                                </span>
                                                                            </>
                                                                        );
                                                                    })()}
                                                                </div>
                                                            ) : (
                                                                <span style={{ padding: "0px 5px", fontSize: "14px" }}>No reply message selected</span>
                                                            )}
                                                        </div>
                                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>

                                                            <textarea
                                                                ref={textareaRef}
                                                                value={chatMessage}
                                                                onChange={handleChange}
                                                                onKeyDown={handleKeyDown}
                                                                placeholder="Write a message...."
                                                                style={{
                                                                    fontSize: "15px",
                                                                    lineHeight: "25px", // Slightly larger than font size for partial visibility
                                                                    height: "40px",
                                                                    padding: "8px 31px 8px 11px",
                                                                    width: "100%",
                                                                    outline: "none",
                                                                    borderRadius: "5px",
                                                                    border: "solid 1px #d6d6d6",
                                                                    resize: "none",
                                                                    overflowY: "auto",
                                                                    maxHeight: "250px", // Enforces max height for scrolling
                                                                }}
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    if (editFlag) {
                                                                        // Editing an existing message
                                                                        console.log("Editing message:", chatMessage);

                                                                        handleEditMessage(chatMessage, editConversationId, editMessageId);
                                                                        setEditFlag(false);
                                                                        setchatMessage("");
                                                                    } else if (replyFlag) {
                                                                        handleReplyMessage(chatMessage, replyMessage?.conversationId, replyMessage?.parentMessageId);
                                                                        setReplyFlag(false);
                                                                        setchatMessage("");
                                                                    } else {
                                                                        // Sending a new message
                                                                        const recipientId = history?.participants[0] === user?._id ? history?.participants[1] : history?.participants[0];

                                                                        sendMessage(recipientId, history?.jobId);
                                                                        setchatMessage("");
                                                                    }
                                                                }}
                                                                className="btn btnsend"
                                                                style={{
                                                                    backgroundColor: chatMessage?.length > 0 ? "#5db2f7" : "#bbb",
                                                                }}
                                                                disabled={chatMessage?.length === 0}
                                                            >
                                                                {editFlag ? "Edit" : replyFlag ? "Reply" : "Send"}{" "}
                                                            </button>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="recent-chats" style={{ height: "92%", overflow: "scroll" }}>
                                        <div
                                            className="chatSlider"
                                            style={{
                                                borderBottom: "1px solid #e0e0e0",

                                                // marginTop: '20px',
                                            }}
                                        >
                                            <div className="" style={{ width: "100%" }}>
                                                <div className="" style={{ margin: "0px auto" }}>
                                                    <div
                                                        className=""
                                                        style={{
                                                            fontSize: "xx-large",
                                                            fontWeight: "500",
                                                            margin: "0px 2rem",
                                                        }}
                                                    >
                                                        Messages
                                                    </div>

                                                    <div
                                                        className="sliderSearch"
                                                        style={{
                                                            display: "flex",
                                                            border: "1px solid #ddd",
                                                            marginTop: "20px",
                                                        }}
                                                    >
                                                        <LuSearch
                                                            style={{
                                                                fontSize: "24px",
                                                                color: "#484848",
                                                                fontWeight: "400",
                                                            }}
                                                        />
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            value={search}
                                                            onChange={(e) => {
                                                                handleSearch(e);
                                                                setSearch(e.target.value);
                                                            }}
                                                            style={{
                                                                fontSize: "14px",
                                                                lineHeight: "1.14285714",
                                                                fontWeight: "400",
                                                                border: "0",
                                                                outline: "0",
                                                                flex: "1",
                                                                padding: "0 8px",
                                                                height: "24px",
                                                                color: "#484848",
                                                            }}
                                                        />

                                                        {search && (
                                                            <RxCross1
                                                                height={24}
                                                                width={24}
                                                                fill="#484848"
                                                                fontSize={20}
                                                                cursor={"pointer"}
                                                                onClick={() => {
                                                                    setSearch("");
                                                                    handleSearch({ target: { value: "" } });
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={isDropdownOpen ? "chatSliderDropdown1" : ""}></div>

                                            <div className="mb-4"></div>
                                        </div>

                                        <div className="chats">
                                            {loader ? (
                                                <>
                                                    <div className="chats text-center pt-3" style={{ width: "100%" }}>
                                                        <div
                                                            className="spinner-border "
                                                            role="status"
                                                            style={{
                                                                width: "27px",
                                                                height: "27px",
                                                                marginTop: "20px",
                                                                color: "#5db2f7",
                                                            }}
                                                        ></div>
                                                        <p
                                                            style={{
                                                                fontSize: "18px",
                                                                fontWeight: "400",
                                                                color: "gray",
                                                            }}
                                                            className=" text-center"
                                                        >
                                                            Loading...
                                                        </p>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {allRooms && allRooms.length > 0 ? (
                                                        <>
                                                            {allRooms
                                                                .filter((item) => item.participants[1]?.name?.toLowerCase().includes(search.toLowerCase()))
                                                                .map((item, index) => (
                                                                    <div key={index} className="wholeBox">
                                                                        <div
                                                                            onClick={() => {
                                                                                joinRoom(item.roomId, item.participants[1], item.participants[0],item._id);
                                                                                setViewMesage(true);
                                                                            }}
                                                                            key={index}
                                                                            href="#"
                                                                            className={chatActive == item.roomId ? "chat-active" : "chat"}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    // flexDirection: "row",
                                                                                    // alignItems: "center",
                                                                                }}
                                                                            >
                                                                                {item?.participants[1]?.resume?.PersonalDetails?.photo?.url !== "" ? (
                                                                                    <div className="">
                                                                                        <img
                                                                                            src={item.participants[1]?.resume?.PersonalDetails?.photo?.url}
                                                                                            alt="Profile"
                                                                                            style={{
                                                                                                width: "50px",
                                                                                                height: "50px",
                                                                                                objectFit: "cover",
                                                                                                borderRadius: "50%",
                                                                                                // border: "2px solid gray",
                                                                                                marginRight: "1rem",
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="round">
                                                                                        <div className="content">{item?.participants[1]?.name.charAt(0)}</div>
                                                                                    </div>
                                                                                )}
                                                                                <div className="" style={{ width: "100%" }}>
                                                                                    <div className="" style={{ display: "flex" }}>
                                                                                        <a href={`/previewFreelancer/${item?.participants[1]?.resume?.User}`} target="_blank">
                                                                                            <span
                                                                                                className="title"
                                                                                                style={{
                                                                                                    fontWeight: "600",
                                                                                                    fontSize: "16px",
                                                                                                    lineHeight: "1.25",
                                                                                                    textOverflow: "ellipsis",
                                                                                                    whiteSpace: "nowrap",
                                                                                                    overflow: "hidden",
                                                                                                    color: "#333",
                                                                                                }}
                                                                                            >
                                                                                                {item.participants[1]?.name}
                                                                                            </span>
                                                                                        </a>
                                                                                        <div
                                                                                            style={{
                                                                                                paddingLeft: "24px",
                                                                                                marginLeft: "auto",
                                                                                                display: "flex",
                                                                                                flexShrink: "0",
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: "12px",
                                                                                                    lineHeight: "1.33333333",
                                                                                                    fontWeight: "500",
                                                                                                    textAlign: "right",
                                                                                                    color: "#8a8a8a",
                                                                                                }}
                                                                                            >
                                                                                                {item.messages && item.messages.length > 0 ? <>{item.messages[item.messages.length - 1].timestamp.slice(8, 10) + "/" + item.messages[item.messages.length - 1].timestamp.slice(5, 7) + "/" + item.messages[item.messages.length - 1].timestamp.slice(0, 4)}</> : <></>}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>

                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            lineHeight: "1.57142857",
                                                                                            fontWeight: "400",
                                                                                            textOverflow: "ellipsis",
                                                                                            whiteSpace: "nowrap",
                                                                                            overflow: "hidden",
                                                                                            color: "#8A8A8A",
                                                                                            padding: "2px 0 0",
                                                                                        }}
                                                                                    >
                                                                                        {item.jobId && item.jobId.profile ? (item.jobId.profile.length > 27 ? `${item.jobId.profile.substring(0, 27)}...` : item.jobId.profile) : ""}
                                                                                    </span>

                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            lineHeight: "1.57142857",
                                                                                            // fontWeight: '400',
                                                                                            color: "#666",
                                                                                            display: "flex",
                                                                                        }}
                                                                                    >
                                                                                        {item.messages && item.messages.length > 1 ? (
                                                                                            <>
                                                                                                {item.messages[1].status === "unread" && item.messages[1].receiver === user._id ? (
                                                                                                    <>
                                                                                                        <p style={{ fontWeight: "400" }}>
                                                                                                            {(() => {
                                                                                                                const senderName = item.messages[1]?.sender === user._id ? "You: " : item?.participants[1]?.name.split(" ")[0] + ": "; // First word from name
                                                                                                                const messageContent = item.messages[1]?.content.replace(/@\[(.*?)\]\(.*?\)/g, "$1") || "";

                                                                                                                const totalLength = senderName.length + messageContent.length;

                                                                                                                if (totalLength > 38) {
                                                                                                                    const availableChars = 38 - senderName.length;
                                                                                                                    return senderName + messageContent.slice(0, availableChars) + "...";
                                                                                                                }
                                                                                                                return senderName + messageContent;
                                                                                                            })()}
                                                                                                        </p>
                                                                                                        {readMesCount(item.messages) === 0 ? null : (
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    marginLeft: "auto",
                                                                                                                    borderRadius: "50px",
                                                                                                                    backgroundColor: "#0C5CB7",
                                                                                                                    color: "white",
                                                                                                                    height: "17px",
                                                                                                                    width: "17px",
                                                                                                                    fontSize: "10px",
                                                                                                                    textAlign: "center",
                                                                                                                }}
                                                                                                            >
                                                                                                                {readMesCount(item.messages)}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <p style={{ fontWeight: "400" }}>
                                                                                                            {(() => {
                                                                                                                const senderName = item.messages[1]?.sender === user._id ? "You: " : item?.participants[1]?.name.split(" ")[0] + ": "; // First word from name
                                                                                                                const messageContent = item.messages[1]?.content.replace(/@\[(.*?)\]\(.*?\)/g, "$1") || "";

                                                                                                                const totalLength = senderName.length + messageContent.length;

                                                                                                                if (totalLength > 38) {
                                                                                                                    const availableChars = 38 - senderName.length;
                                                                                                                    return senderName + messageContent.slice(0, availableChars) + "...";
                                                                                                                }
                                                                                                                return senderName + messageContent;
                                                                                                            })()}
                                                                                                        </p>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <p style={{ fontWeight: "400" }}>...</p>
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <button
                                                                                className="btn status"
                                                                                style={{
                                                                                    background: "#fffaeb",
                                                                                    color: "#333",
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "1.33333333",
                                                                                    fontWeight: "500",
                                                                                    borderRadius: "4px",
                                                                                    padding: "5px 8px",
                                                                                    marginRight: "8px",
                                                                                }}
                                                                            >
                                                                                {item.appliedJobId ? <>{item.appliedJobId.status === "Assignment" ? "Assigment Sent" : item.appliedJobId.status === "Application_Sent" ? "Application Received" : item.appliedJobId.status === "NotIntrested" ? "Not Interested" : item.appliedJobId.status}</> : <>General</>}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="chats text-center" style={{ width: "100%" }}>
                                                                <RiChatOffLine className="mx-auto mt-5" style={{ fontSize: "50px", color: "#5db2f7" }} />
                                                                <p
                                                                    style={{
                                                                        fontSize: "20px",
                                                                        fontWeight: "400",
                                                                        color: "gray",
                                                                    }}
                                                                    className="mt-2 text-center"
                                                                >
                                                                    No chat available!!
                                                                </p>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>

                {/* Model 1: Send Assigment */}
                <div className="modal fade" id="sendassignment" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" style={{ borderRadius: "20px" }}>
                        <div className="modal-content PopupBox" style={{ borderRadius: "20px" }}>
                            <div className="modal-body" style={{ borderRadius: "20px" }}>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div>
                                        <p
                                            style={{
                                                fontSize: "38px",
                                                fontWeight: "500",
                                                color: "#444444",
                                            }}
                                        >
                                            {" "}
                                            <img
                                                src={sendAssignmentLogo}
                                                alt=".."
                                                style={{
                                                    width: "38px",
                                                    height: "35px",
                                                    marginRight: "8px",
                                                }}
                                            />{" "}
                                            Send Assignment
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: "30px",
                                            marginLeft: "auto",
                                            marginTop: "9px",
                                        }}
                                    >
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                                <p
                                    style={{
                                        fontSize: "23px",
                                        fontWeight: "700",
                                        marginTop: "5px",
                                    }}
                                >
                                    To: Anil Bansal
                                </p>
                                <p
                                    style={{
                                        fontSize: "23px",
                                        fontWeight: "500",
                                        marginTop: "15px",
                                    }}
                                >
                                    Add Description
                                </p>
                                <textarea
                                    rows={8}
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        padding: "10px",
                                    }}
                                    name="description"
                                    value={inputDataSendAssigment.description}
                                    onChange={handleInputSendAssigment}
                                ></textarea>
                                <div
                                    className="py-2 "
                                    style={{
                                        color: "black",
                                        marginTop: "22px",
                                        backgroundColor: "#D6EAF8",
                                        width: "300px",
                                        paddingLeft: "30px",
                                        borderRadius: "8px",
                                        fontSize: "20px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Add Attachment
                                </div>
                                <p
                                    style={{
                                        marginTop: "10px",
                                        color: "black",
                                        fontWeight: "400",
                                    }}
                                >
                                    File Limit 5MB, Upload pdf,png,zip,gif,jpg,jpeg
                                </p>
                                <p
                                    style={{
                                        fontSize: "23px",
                                        fontWeight: "500",
                                        marginTop: "20px",
                                    }}
                                >
                                    Select Submission Date
                                </p>
                                <input
                                    className="px-5 py-4"
                                    type="date"
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        fontSize: "18px",
                                    }}
                                    name="date"
                                    value={inputDataSendAssigment.date}
                                    onChange={handleInputSendAssigment}
                                />
                                <div className="text-end">
                                    <button className="sendAssignmentBtn" onClick={handleSubmitSendAssigment}>
                                        Send Assignment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Model 2: Send Report */}
                <div className="modal fade" id="sendreport" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" style={{ borderRadius: "20px" }}>
                        <div className="modal-content PopupBox" style={{ borderRadius: "20px" }}>
                            <div className="modal-body my-4" style={{ borderRadius: "20px" }}>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div>
                                        <p
                                            style={{
                                                fontSize: "36px",
                                                fontWeight: "500",
                                                color: "#444444",
                                            }}
                                        >
                                            {" "}
                                            <img
                                                src={reportLogo}
                                                alt=".."
                                                style={{
                                                    width: "38px",
                                                    height: "40px",
                                                    marginRight: "8px",
                                                }}
                                            />{" "}
                                            Report Applicant
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: "30px",
                                            marginLeft: "auto",
                                            marginTop: "9px",
                                        }}
                                    >
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>

                                <p
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: "500",
                                        marginTop: "25px",
                                    }}
                                >
                                    Select the reason to report applicant
                                </p>
                                <div className="dropdown">
                                    <button
                                        className="btn chatSliderDropdown2 dropdown-toggle pr-4 "
                                        type="button"
                                        id="dropdownMenuButton2"
                                        data-bs-toggle="dropdown"
                                        ref={dropdownRef1}
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            width: "100%",
                                            borderRadius: "10px",
                                            fontSize: "20px",
                                            marginTop: "5px",
                                            border: "solid 1px black",
                                            color: "white",
                                            paddingLeft: "20px",
                                            paddingTop: "20px",
                                            paddingBottom: "20px",
                                        }}
                                        onClick={handleDropdownToggle1}
                                    >
                                        <span
                                            style={{
                                                color: "black",
                                                textAlign: "start",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {inputDataSendReport && inputDataSendReport.reason ? inputDataSendReport.reason.slice(0, 24) : ""}
                                        </span>
                                        {isDropdownOpen1 || isButtonClicked1 ? (
                                            <IoIosArrowUp
                                                style={{
                                                    fontSize: "26px",
                                                    color: "black",
                                                    marginLeft: "auto",
                                                    marginRight: "-5px",
                                                    marginTop: "-2px",
                                                }}
                                            />
                                        ) : (
                                            <IoIosArrowDown
                                                style={{
                                                    fontSize: "26px",
                                                    color: "black",
                                                    marginLeft: "auto",
                                                    marginRight: "-5px",
                                                    marginTop: "-2px",
                                                }}
                                            />
                                        )}
                                    </button>

                                    <ul
                                        className={`dropdown-menu chatSliderDrowpdownMenu1 ${isDropdownOpen1 ? "show" : ""}`}
                                        aria-labelledby="dropdownMenuButton2"
                                        style={{
                                            width: "100%",
                                            borderRadius: "10px",
                                            border: "none",
                                            paddingtop: "10px",
                                            paddingBottom: "10px",
                                            paddingLeft: "30px",
                                            paddingRight: " 40px",
                                            fontSize: "20px",
                                            fontWeight: "500",
                                            backgroundColor: "#D6EAF8",
                                        }}
                                    >
                                        {reason.map((item, index) => (
                                            <li
                                                key={index}
                                                className={` dropdown-item  ${inputDataSendReport.reason === item ? "selected" : ""}`}
                                                style={{
                                                    fontWeight: "500",
                                                    // padding: '10px',
                                                    paddingLeft: "20px",
                                                    marginTop: "5px",
                                                    marginBottom: "5px",
                                                    cursor: "pointer",
                                                    borderRadius: "10px",
                                                }}
                                                onClick={() => handleDropdownItemClick1(item)}
                                            >
                                                {item.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {inputDataSendReport.reason == "Others" ? (
                                    <>
                                        <p
                                            style={{
                                                fontSize: "22px",
                                                fontWeight: "500",
                                                marginTop: "30px",
                                            }}
                                        >
                                            Mention the reason
                                        </p>
                                        <input
                                            className=""
                                            type="text"
                                            style={{
                                                width: "100%",
                                                borderRadius: "10px",
                                                fontSize: "18px",
                                                marginTop: "5px",
                                                padding: "20px",
                                            }}
                                            name="otherReason"
                                            value={inputDataSendReport.otherReason}
                                            onChange={handleInputSendReport}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                                <p
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: "500",
                                        marginTop: "30px",
                                    }}
                                >
                                    Description
                                </p>
                                <textarea
                                    rows={5}
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        padding: "10px",
                                    }}
                                    name="description"
                                    value={inputDataSendReport.description}
                                    onChange={handleInputSendReport}
                                />

                                <div className="text-end">
                                    <button className="sendAssignmentBtn mt-4" onClick={handleSubmitSendReport}>
                                        Report Applicant
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default MessageEmployer;
