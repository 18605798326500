// TalentCategories.jsx
import React, { useState, useEffect } from "react";
import "./css/LandingPageNew.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CategoryShimmer from "../Other/ShimmerUI";
const Categories = () => {
    const [categoryData, setCategoryData] = useState([]);
    const navigate = useNavigate();
    const categories = [
        { title: "Development & IT", rating: 4.85, skills: 1853 },
        { title: "AI Services", rating: 4.85, skills: 1853 },
        { title: "Design & Creative", rating: 4.85, skills: 1853 },
        { title: "Sales & Marketing", rating: 4.85, skills: 1853 },
        { title: "Writing & Translation", rating: 4.85, skills: 1853 },
        { title: "Admin & Customer Support", rating: 4.85, skills: 1853 },
        { title: "Finance & Accounting", rating: 4.85, skills: 1853 },
        { title: "Engineering & Architecture", rating: 4.85, skills: 1853 },
    ];
    const fetchData = async () => {
        try {
            const categoryResponse = await axios.get(`/api/data/project-category`);
            const categories = categoryResponse?.data || [];

            const categorySpecializationData = await Promise.all(
                categories.map(async (category) => {
                    const specializationResponse = await axios.get(`/api/data/specialization`, {
                        params: { profileCategory: category },
                    });
                    const specializations = specializationResponse?.data || [];
                    return {
                        category,
                        specializations,
                    };
                })
            );

            setCategoryData(categorySpecializationData);
        } catch (error) {
            console.error("Error fetching categories and specializations:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    console.log(categoryData);
    const handleNavigateToFindTalent = (category) => {
      // Store category in sessionStorage instead of navigation state
      sessionStorage.setItem('selectedCategory', category);
      navigate("/matchedHourlyProject/all");
  };

    return (
        <div className="talent-categories">
            <h1 className="title">Browse talent by category</h1>
            <p className="subtitle">
                Looking for work?{" "}
                <Link to="/matchedHourlyProject/all" className="browse-link">
                    Browse jobs
                </Link>
            </p>

            <div className="categories-grid" style={{ width: "100%" }}>
                {categoryData.length === 0 && <CategoryShimmer />}
                {categoryData.map((item, index) => (
                    <div key={index} className="category-card" style={{cursor:"pointer"}} onClick={() => handleNavigateToFindTalent(item?.category)}>
                        <h2 className="category-title">{item?.category}</h2>
                        <div className="category-stats">
                            <div className="rating">
                                <svg className="star-icon" viewBox="0 0 24 24">
                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                                </svg>
                                <span>{Math.floor(Math.random() * 5) + 1}/5
                                </span>
                            </div>
                            <div className="skills">{item?.specializations?.length} skills</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Categories;
