import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import Spinner from "../Layout/Spinner";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import "./css/CoverLetter.scss";

function CoverLetter() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const { state } = useLocation();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  if (state == null) {
    navigate("/");
  }
  const [applyJob, setApplyJob] = useState({
    _id: state && state._id,
    coverLetter: "",
    availability: true,
    assessment: "",
  });

  const handleChange = (e) => {
    setApplyJob({
      ...applyJob,
      [e.target.name]: e.target.value,
    });
  };

  const available = () => {
    setApplyJob({
      ...applyJob,
      availability: true,
    });
  };

  const notAvailable = () => {
    setApplyJob({
      ...applyJob,
      availability: false,
    });
  };

  const handleApplyJob = async () => {
    if (user && user) {
      if (user && !user.resume) {
        toast.error("Please create your resume");
        navigate("/addResume");
      } else {
        setLoading(true);
        if (!applyJob.coverLetter) {
          setLoading(false);
          toast.error("Cover Letter is required!!");
        } else {
          try {
            await axios.post(
              "/api/user/apply",
              {
                _id: applyJob._id,
                coverLetter: applyJob.coverLetter,
                availability: applyJob.availability,
                assessment: applyJob.assessment,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  token: localStorage.getItem("token"),
                },
              }
            );
            setLoading(false);
            toast.success("Application Saved Successfully!", {
              style: {
                paddingTop: "18px",
                paddingBottom: "18px",
              },
            });
            const timer = setTimeout(() => {
              navigate("/matchedJobs");
            }, 1000);

            timer();
          } catch (error) {
            setLoading(false);
            toast.error(error.response.data, {
              style: {
                padding: "18px",
              },
            });
          }
        }
      }
    } else {
      toast.error("Please Login!!");
    }
  };

  // console.log(user.role)

  return (
    <>
      {<Toaster />}
      <div
        className="pt-2"
        style={{ backgroundColor: "rgba(224, 220, 243, 0.212)" }}
      >
        <div className="step-content container">
          <div
            className="sectionn  my-5 mx-auto"
            style={{ maxWidth: "900px", backgroundColor: "transparent" }}
          >
            <h3
              className=" text-start mb-5 text-center"
              style={{ marginTop: "60px", fontSize: "27px", lineHeight: "1" }}
            >
              {state && state.profile}
              {state && state.jobType === "Remote" ? (
                <> work from home</>
              ) : (
                <></>
              )}{" "}
              {state && state.opportunityType.toLowerCase()} at{" "}
              {state && state.organisationName}
              {state && state.jobType === "Remote" ? (
                <></>
              ) : (
                <> {state && state.city}</>
              )}
            </h3>
            <div
              className="section1 "
              style={{
                backgroundColor: "white",
                boxShadow:
                  "3px 0 8px -3px rgba(233, 233, 250, 0.808),-3px 0 8px -3px rgba(233, 233, 250, 0.808), 0 3px 8px -3px rgba(233, 233, 250, 0.808), 0 -3px 8px -3px rgba(233, 233, 250, 0.808)",
              }}
            >
              <h2 style={{ marginTop: "-10px", marginBottom: "-8px" }}>
                Cover letter
              </h2>
              <div className="form-group mt-5">
                <p style={{ fontWeight: "500", fontSize: "15px" }}>
                  Why should you be hired for this role?
                </p>
                <br></br>
                <div
                  style={{
                    marginTop: "-35px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <i
                    className="fa fa-clone mr-2"
                    style={{ color: "#65A7E8", marginTop: "2px" }}
                    aria-hidden="true"
                  ></i>
                  <p style={{ color: "#65A7E8", fontSize: "15px" }}>
                    Copy from your last application & edit
                  </p>
                </div>
              </div>
              <div className="" style={{ marginTop: "-10px" }}>
                <textarea
                  style={{ fontSize: "14px" }}
                  className="form-control"
                  id="coverLetter"
                  rows="6"
                  name="coverLetter"
                  value={applyJob.coverLetter}
                  onChange={handleChange}
                  placeholder="Mention in detail what relevant skill or past experience you have for this job/intership. What excites you about this job/intership? Why would you be a good fit?"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="step-content container" style={{ marginTop: "-80px" }}>
          <div className="sectionn mx-auto" style={{ maxWidth: "900px" }}>
            <div
              className="section1 "
              style={{
                backgroundColor: "white",
                boxShadow:
                  "3px 0 8px -3px rgba(233, 233, 250, 0.808),-3px 0 8px -3px rgba(233, 233, 250, 0.808), 0 3px 8px -3px rgba(233, 233, 250, 0.808), 0 -3px 8px -3px rgba(233, 233, 250, 0.808)",
              }}
            >
              <h2 style={{ marginTop: "-10px", marginBottom: "-8px" }}>
                Your availability
              </h2>
              <div className="form-group mt-5 border px-5 pt-3">
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                    marginBottom: "20px",
                  }}
                >
                  Confirm your availability
                </p>
                <div className="form-check">
                  <input
                    className="form-check-input "
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onClick={available}
                  />
                  <p style={{ fontWeight: "500", fontSize: "13px" }}>
                    Yes, I am available for {state && state.duration} months
                    starting immediately for a{" "}
                    {(state && state.isPartTimeAllowed === "yes") ||
                    (state && state.isPartTimeAllowed === "Yes") ||
                    (state && state.isPartTimeAllowed === "YES") ? (
                      <>part-time</>
                    ) : (
                      <>full-time</>
                    )}{" "}
                    {state && state.opportunityType.toLowerCase()}
                  </p>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    onClick={() => {
                      notAvailable();
                    }}
                  />
                  <p style={{ fontWeight: "500", fontSize: "13px" }}>
                    <p>
                      {" "}
                      No{" "}
                      <i style={{ color: "gray" }}>
                        (Please specify your availability)
                      </i>
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="step-content container" style={{ marginTop: "-50px" }}>
          <div className="sectionn mx-auto" style={{ maxWidth: "900px" }}>
            <div
              className="section1 "
              style={{
                backgroundColor: "white",
                boxShadow:
                  "3px 0 8px -3px rgba(233, 233, 250, 0.808),-3px 0 8px -3px rgba(233, 233, 250, 0.808), 0 3px 8px -3px rgba(233, 233, 250, 0.808), 0 -3px 8px -3px rgba(233, 233, 250, 0.808)",
              }}
            >
              <h2 style={{ marginTop: "-10px", marginBottom: "-8px" }}>
                Assessment
              </h2>
              <div className="form-group mt-5">
                <p style={{ fontWeight: "500", fontSize: "15px" }}>
                  Q1. Please share links of your past work.
                </p>
                <br></br>
                <div style={{ marginTop: "-40px" }}>
                  <p
                    style={{
                      color: "gray",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                  >
                    If you want to share any documents or files, Please upload
                    it on <u style={{ color: "#65A7E8" }}>Goodle Drive</u> or{" "}
                    <u style={{ color: "#65A7E8" }}>DropBox</u> and paste the
                    public link in the answer.
                  </p>
                </div>
              </div>
              <div className="" style={{ marginTop: "-2px" }}>
                <textarea
                  style={{ fontSize: "15px" }}
                  className="form-control"
                  id="assessment"
                  rows="6"
                  name="assessment"
                  value={applyJob.assessment}
                  onChange={handleChange}
                  placeholder="Enter text..."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="center text-center my-5" style={{ margin: "auto" }}>
            <button
              type="button"
              onClick={handleApplyJob}
              style={{
                fontSize: "15px",
                borderRadius: "7px",
                width: "130px",
              }}
              className="btn btn-info01"
            >
              {loading ? <Spinner /> : <span>Submit</span>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoverLetter;
