import React, { useEffect, useState } from "react";
import "./css/HireAnAgency.scss";
import { hireAnAgency_1, hireAnAgency_2, hireAnAgency_3, hireAnAgency_4, hireAnAgency_5, hireAnAgency_6, hireAnAgency_7, rightArrow } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
const HireAnAgency = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const categories = [
        {
            icon: `${hireAnAgency_2}`,
            title: "Development & IT",
            services: ["App development", "Robotics", "Software Development", "Web Development"],
        },
        {
            icon: `${hireAnAgency_3}`,
            title: "Design & Creative",
            services: ["Branding", "Design", "Graphic Design", "Video Production"],
        },
        {
            icon: `${hireAnAgency_4}`,
            title: "Sales & Marketing",
            services: ["Advertising", "Affiliate Marketing Companies", "Content Marketing", "Creative"],
        },
        {
            icon: `${hireAnAgency_5}`,
            title: "Writing & Translation",
            services: ["Translation", "Copywriting", "Content Writing", "Ghostwriting"],
        },
        {
            icon: `${hireAnAgency_6}`,
            title: "Admin & Customer Support",
            services: ["Survey", "BPO", "Call Center", "Virtual Assistant"],
        },
        {
            icon: `${hireAnAgency_7}`,
            title: "Finance & Accounting",
            services: ["Accounting", "Bookkeeping", "HR Consulting", "Payroll Processing"],
        },
    ];

    // const allCategories = [
    //     { icon: "Development & IT", title: "Development & IT", services: ["Web Development", "Software Development", "iOS App Development", "Android App Development", "Game Development", "Web Development", "Software Development", "iOS App Development", "Android App Development", "Game Development", "Web Development", "Software Development", "iOS App Development", "Android App Development", "Game Development"] },
    //     { icon: "Design & Creative", title: "Design & Creative", services: ["Mobile App Design", "Web Development"] },
    //     { icon: "Sales & Marketing", title: "Sales & Marketing", services: ["Marketing", "Sales"] },
    //     { icon: "Writing & Translation", title: "Writing & Translation", services: ["Content Writing", "Translation"] },
    //     { icon: "Admin & Customer Support", title: "Admin & Customer Support", services: ["Customer Support", "Administrative Tasks"] },
    //     { icon: "Finance & Accounting", title: "Finance & Accounting", services: ["Accounting", "Financial Reporting"] },
    // ];

    const allCategories = [
        {
            title: "Accounting & Consulting",
            services: ["Personal & Professional Coaching", "Accounting & Bookkeeping", "Financial Planning", "Recruiting & Human Resources", "Management Consulting & Analysis", "Other - Accounting & Consulting"],
        },
        {
            title: "Admin Support",

            services: ["Data Entry & Transcription Services", "Virtual Assistance", "Project Management", "Market Research & Product Reviews", "Customer Service", "Community Management & Tagging", "Customer Service & Tech Support"],
        },
        {
            title: "Data Science & Analytics",
            services: ["Data Analysis", "Data Extraction/ETL", "Data Mining & Management", "AI & Machine Learning", "Data Engineering", "Data Visualization"],
        },
        {
            title: "Design & Creative",
            services: ["Art & Illustration", "Audio & Music Production", "Branding & Logo Design", "NFT, AR/VR & Game Art", "Graphic, Editorial & Presentation Design", "Performing Arts", "Photography", "Product Design", "Video & Animation"],
        },
        {
            title: "Engineering & Architecture",
            services: ["Building & Landscape Architecture", "Chemical Engineering", "Civil & Structural Engineering", "Contract Manufacturing", "Electrical & Electronic Engineering", "Interior & Trade Show Design", "Energy & Mechanical Engineering", "Physical Sciences", "3D Modeling & CAD"],
        },
        {
            title: "IT & Networking",
            services: ["Database Management & Administration", "ERP/CRM Software", "Information Security & Compliance", "Network & System Administration", "DevOps & Solution Architecture"],
        },
        {
            title: "Legal",
            services: ["Corporate & Contract Law", "International & Immigration Law", "Finance & Tax Law", "Public Law"],
        },
        {
            title: "Sales & Marketing",
            services: ["Digital Marketing", "Lead Generation & Telemarketing", "Marketing, PR & Brand Strategy", "Translation", "Language Tutoring & Interpretation", "Translation & Localization Services"],
        },
        {
            title: "Web, Mobile & Software Development",
            services: ["Blockchain, NFT & Cryptocurrency", "AI Apps & Integration", "Desktop Application Development", "Ecommerce Development", "Game Design & Development", "Mobile Development", "QA Testing", "Scripts & Utilities", "Web & Mobile Design", "Web Development", "Other - Software Development"],
        },
        {
            title: "Writing",
            services: ["Sales & Marketing Copywriting", "Content Writing", "Editing & Proofreading Services", "Professional & Business Writing"],
        },
    ];
    const steps = [
        {
            number: 1,
            title: "Select your category",
            description: "Whatever you need, assistance is available.",
        },
        {
            number: 2,
            title: "Explore the agencies",
            description: "There are thousands to choose from",
        },
        {
            number: 3,
            title: "Invite an agency",
            description: "Review the profiles, choose your favorite, and make contact.",
        },
        {
            number: 4,
            title: "Start a conversation",
            description: "Define your objectives and begin your journey!",
        },
    ];

    const navigateToFreelancerDetailsForProfile = (selectedProfile) => {

        console.log(selectedProfile);
        sessionStorage.setItem("selectedProfile", selectedProfile);
        navigate("/freelancer-details");
    };
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };
    return (
        <div className="agency-container">
            {/* Hero Section */}
            <div className="agency-hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Make it a reality with an agency</h1>
                        <p>Whether it's niche jobs or complex programs, there's an agency ready to assist.</p>
                        <Link to="/freelancer-details" style={{ color: "white" }}>
                            <button className="primary-btn">Search Agencies</button>
                        </Link>
                    </div>
                </div>
                <div className="hero-image">
                    <img src={hireAnAgency_1} alt="Person working on laptop" />
                </div>
            </div>
            <div className="agency-categories-container">
                <h1 className="main-title">Explore our most in-demand categories</h1>

                <div className="categories-grid">
                    {categories.map((category, index) => (
                        <div key={index} className="category-card">
                            <div>
                                <img src={category.icon} alt="" />
                            </div>
                            <h3 className="category-title">{category.title}</h3>
                            <div className="services-list">
                                {category.services.map((service, serviceIndex) => (
                                    <span key={serviceIndex} className="service-item">
                                        {service}
                                    </span>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <section className="how-it-works">
                    <h2>How it works</h2>
                    <div className="steps-container">
                        {steps.map((step, index) => (
                            <div key={index} className="step-item">
                                <div className="step-number">{step.number}</div>
                                <div className="content">
                                    <h3>{step.title}</h3>
                                    <p>{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                <div className="search-agencies-by-category">
                    <h2>Search for agencies by category</h2>
                    <p>Take a look at the agencies that work in your world.</p>
                    <div className="displayCategoriesWrapper">
                        <div className="categories">
                            {allCategories.map((category, index) => (
                                <div key={index} className={`category ${selectedCategory?.title === category.title ? "active" : ""}`} onClick={() => handleCategoryClick(category)}>
                                    <h1>{category.title}</h1>
                                </div>
                            ))}
                        </div>
                        {selectedCategory && (
                            <div className="skills">
                                {selectedCategory.services.map((service, index) => (
                                    <h3 key={index} style={{cursor:"pointer"}} onClick={() => navigateToFreelancerDetailsForProfile(service)}>{service}</h3>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="faq-section">
                    <h2>Frequently asked questions</h2>
                    <div className="faq-list">
                        <div className="faq-item">
                            <h3>What is the experience like collaborating with an agency via Workcroft?</h3>
                            <p>You have the flexibility to decide how you'd like to collaborate once the project begins.</p>
                        </div>

                        <div className="faq-item">
                            <h3>What can I anticipate from agencies during the hiring process?</h3>
                            <p>They will provide all the details you need to make an informed decision and may ask follow-up questions to gain a clearer understanding of your project.</p>
                        </div>

                        <div className="faq-item">
                            <h3>How can I draw the right agencies to my project?</h3>
                            <p>Our top agencies are highly sought after, but you can improve your chances by providing a clear and detailed brief.</p>
                        </div>
                        <p>
                            Still have questions?{" "}
                            <Link to="/support" style={{ textDecoration: "underline" }}>
                                Try our Help Center <img src={rightArrow} style={{ width: "10px", height: "10px" }} alt="" />
                            </Link>
                        </p>
                    </div>
                </div>

                {/* <section className="agency-search">
                    <h2>Search for agencies by category</h2>
                    <p className="subtitle">Take a look at the agencies that work in your world.</p>

                    <div className="categories-section">
                        <div className="main-categories">
                            {agencyCategories.map((category, index) => (
                                <div key={index} className={`category-link ${index === 0 ? "active" : ""}`}>
                                    {category}
                                </div>
                            ))}
                        </div>

                        <div className="services-grid">
                            {services.map((service, index) => (
                                <span key={index} className="service-link">
                                    {service}
                                </span>
                            ))}
                        </div>
                    </div>
                </section> */}
            </div>
        </div>
    );
};

export default HireAnAgency;
