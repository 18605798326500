import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Spinner from '../Layout/Spinner';
import './css/OrganizationDetails.scss'

function OrganizationDetails() {

    const goBack = () => {
        console.log("clicked");
        window.location.href = document.referrer;
    };
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0)
      },[]);
    const { user } = useSelector(state => state.user);

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState({
        color: "", message: ""
    });

    const [organizationDetails, setOrganizationDetails] = useState({
        name: "", description: "",
        link: {
            url: "",
            linkType: ""
        }
    });

    const handleInputChange = (e) => {
        setOrganizationDetails({
            ...organizationDetails,
            [e.target.name]: e.target.value
        })
    }

    const handleRadioChange = (e) => {
        setOrganizationDetails({
            ...organizationDetails,
            link: {
                ...organizationDetails.link,
                [e.target.name]: e.target.value
            }
        })
    }

    const submit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const { data } = await axios.put(`/api/employer/addOrgDetails/${user._id}`, organizationDetails);

            setResponseData({ color: "blue", message: data.message });
        } catch (error) {
            console.log("Error :- ", error);
            setResponseData({ color: "red", message: error.response.data.message });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        async function getOrgDetails() {
            const { data } = await axios.get(`/api/employer/getOrgDetails/${user._id}`);

            setOrganizationDetails(data.data.organizationDetails);
        }

        if (user) {
            getOrgDetails();
        }
    }, [user])


    return (
        <div className="organization-details">
            <form onSubmit={submit} className="organization-form">
                <h3 className="title">Organization Details</h3>
                <div className="form-box">
                    <div className="input-box">
                        <label htmlFor="organisationName">Organization Name</label>
                        <input type="text" onChange={handleInputChange} value={organizationDetails.name} name='name' />
                    </div>
                    <div className="input-box">
                        <label htmlFor="description">Description</label>
                        <textarea onChange={handleInputChange} value={organizationDetails.description} name="description"></textarea>
                    </div>
                </div>

                <h3 className="title">Verification</h3>
                <div className="check-input-box">
                    <div className="input-box">
                        <input type="radio" onChange={handleRadioChange} name="linkType" id='page' value="page" />
                        <label htmlFor="page">Website or Media Page</label>
                    </div>
                    {organizationDetails.link.linkType === "page" && <div className="input-box">
                        <input type="text" name='url' value={organizationDetails.link.url} onChange={handleRadioChange} />
                    </div>}
                    <div className="input-box">
                        <input type="radio" onChange={handleRadioChange} name="linkType" id='website' value="website" />
                        <label htmlFor="website">Official Company Website</label>
                    </div>
                    {organizationDetails.link.linkType === "website" && <div className="input-box">
                        <input type="text" name='url' value={organizationDetails.link.url} onChange={handleRadioChange} />
                    </div>}
                </div>
                {responseData.message && <div style={{ marginTop: "2rem", textAlign: "center", fontSize: "1.4rem", fontWeight: "500", color: responseData.color }}> {responseData.message} </div>}
                <div style={{ display: "flex", margin: "2rem" }}>
                    <button type="submit" className='btn btn-primary' style={{
                        marginLeft: "auto", marginRight: "auto"
                    }}>
                        {loading ? <Spinner /> : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default OrganizationDetails