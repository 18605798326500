import React, { useEffect } from "react";
import "./css/HowToHire.scss";
import { howToHire_1, howToHire_2, howToHire_3, check } from "../../assets";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const HowToHire = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    const {user } = useSelector((state) => state.user)
    return (
        <div className="howtohire-container">
            {/* Hero Section */}
            <div className="hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Select your preferred method to accomplish tasks</h1>
                        <p>Find freelancers and run projects youDiscover freelancers and manage projects your way on the global work marketplace.r way at the world's work marketplace.</p>
                        <Link to="/freelancer-details" style={{ color: "white" }}>
                            <button className="primary-btn">Find Talent</button>
                        </Link>
                    </div>
                    {!user && <p className="signup-text">
                        Looking to get hired?{" "}
                        <Link to="/how-to-find-work" className="signup-link">
                            Sign up here
                        </Link>
                    </p>}
                </div>
                <div className="hero-image">
                    <img src={howToHire_1} alt="Person working on laptop" />
                </div>
            </div>

            {/* Working Section */}
            <div className="working-section">
                <h2>Let's start working</h2>
                <p>Build relationships and assemble your own Virtual Talent Bench™ for efficient project delivery and significant transformations.</p>
            </div>
            <hr />

            {/* Job Post Section */}
            <div className="job-post-section">
                <div className="job-post-image">
                    <img src={howToHire_2} alt="Person in library" />
                </div>
                <div className="job-post-content">
                    <h2>Post a job and hire a pro</h2>
                    <h3>Connect with talent that understands you through the Talent Marketplace.</h3>
                    <p>Publish your job on the global work marketplace and await proposals from talented individuals worldwide.</p>
                    <p>Our advanced algorithms assist in identifying candidates who are the best match. You can review profiles, portfolios, and reviews before making your decision.</p>
                    <Link to="/freelancer-details" style={{ color: "white" }}>
                        <button className="primary-btn">Find Talent</button>
                    </Link>
                </div>
            </div>

            <div className="approval-section">
                <h2>The mark of approval</h2>
                <hr />
                <div className="approval-content">
                    <p className="approval-intro">You'll receive ample assistance in selecting the right person for the job. Plus, regardless of whom you hire, you can:</p>
                    <ul className="approval-list">
                        <li>
                            <img src={check} alt="Check icon" style={{ width: "15px", height: "15px", objectFit: "cover" }} /> Explore comparable projects they've completed
                        </li>
                        <li>
                            <img src={check} alt="Check icon" style={{ width: "15px", height: "15px", objectFit: "cover" }} /> Directly access proven talent without unnecessary layers
                        </li>
                        <li>
                            <img src={check} alt="Check icon" style={{ width: "15px", height: "15px", objectFit: "cover" }} /> Review their portfolio
                        </li>
                        <li>
                            <img src={check} alt="Check icon" style={{ width: "15px", height: "15px", objectFit: "cover" }} /> Verify qualifications
                        </li>
                        <li>
                            <img src={check} alt="Check icon" style={{ width: "15px", height: "15px", objectFit: "cover" }} /> Conduct a chat or video interview
                        </li>
                    </ul>
                </div>
            </div>

            {/* Safety Section */}
            <div className="safety-section">
                <div className="safety-image">
                    <img src={howToHire_3} alt="Person working on laptop" />
                </div>
                <div className="safety-content">
                    <div className="safety-guarantee">
                        <h2>Your safety is guaranteed</h2>
                        <h3>You receive exactly what you pay for, and we can demonstrate it.</h3>

                        <div className="safety-details">
                            <p>For hourly contracts, we monitor keystrokes and take random screenshots of your freelancer's screen to ensure they are actively engaged.</p>
                            <p>For fixed-price contracts, you agree on milestones and only make payments when those milestones are achieved.</p>
                        </div>
                    </div>

                    <div className="location-info">
                        <h2>All in a single location:</h2>
                        <h3>Once signed in, you'll have your dedicated online space to oversee your project.</h3>
                        <p>Utilize it for securely exchanging files, providing real-time feedback, and processing payments.</p>
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <div className="faq-section">
                <h2>Frequently asked questions</h2>
                <div className="faq-list">
                    <div className="faq-item">
                        <h3>What types of projects are available on Workcroft?</h3>
                        <p>You can find a wide range of computer-based tasks on Workcroft. Popular categories include web, mobile, and software development, design, and copywriting. Additionally, you can also connect with engineers, accountants, and more.</p>
                    </div>

                    <div className="faq-item">
                        <h3>How does Workcroft generate revenue?</h3>
                        <p>We charge connection fees that are necessary for submitting bid proposals and increasing your profile visibility to potential employers.</p>
                    </div>

                    <div className="faq-item">
                        <h3>What is the Top Rated program about?</h3>
                        <p>It's a recognition for talented individuals who consistently deliver exceptional work.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToHire;
