import React, { useState, useEffect } from "react";
import "./css/ViewMoreJobs.scss";
import Select from "react-select";
import prof1 from "../../assets/images/sales.png";
import prof2 from "../../assets/images/marketing.png"
import prof3 from "../../assets/images/computer.png"
import prof4 from "../../assets/images/accounting.png"
import prof5 from "../../assets/images/engineering.png"
import prof6 from "../../assets/images/legal.png"
import prof7 from "../../assets/images/advertising.png"
import prof8 from "../../assets/images/elec.jpg"


import retails from "../../assets/images/retails.jpg"
import bs from "../../assets/images/businessservicess.jpg"
import civil from "../../assets/images/civil.jpg"
import chemical from "../../assets/images/chemical.jpg"
import cw from "../../assets/images/contentwriter.jpg"
import customer from "../../assets/images/customersupport.jpg"
import electronics from "../../assets/images/electronics.jpg"
import manufacturing from "../../assets/images/manufacturing.jpg"
import machanical from "../../assets/images/mechanical.jpg"
import restaraunt from "../../assets/images/resturant.jpg"
import other from "../../assets/images/other.jpg"
import transport from "../../assets/images/transport.jpg"


import job1 from "../../assets/images/remote.png"
import job2 from "../../assets/images/delhi.png"
import job3 from "../../assets/images/mumbai.png"
import job4 from "../../assets/images/banglore.png"
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Spinner from "../Layout/Spinner";
import axios from "axios";



const NoArrowIndicator = () => null;



export default function ViewMoreJobs() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);

  const navigate = useNavigate();
  const jobs = [
    { value: "job1", label: "Web Development" },
    { value: "job2", label: "Wordpress Development" },
    { value: "job3", label: "ReactJs Development" },
    { value: "job4", label: "Backend Development" },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };
  const locations = [
    { value: "location1", label: "Delhi" },
    { value: "location2", label: "Noida" },
    { value: "location3", label: "Bangalore" },
    { value: "location4", label: "Pune" },
  ];
  const [selectedLocoationOptions, setSelectedLocationOptions] = useState([]);

  const handleSelectLocationChange = (selectedLocoationOptions) => {
    setSelectedLocationOptions(selectedLocoationOptions);
  };



  // SEARCH OPTION : 

  const [loading, setLoading] = useState(false);


  const [search, setSearch] = useState({
    title: "",
    location: "",
  });


  const handleChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };


  
  




  const removeTitle = () => {
    setSearch({
      ...search,
      title: "",
    });
  }

  const removeLocation = () => {
    setSearch({
      ...search,
      location: "",
    });
  }


   // See all:


   const seeAll = () => {
    sessionStorage.setItem('searchPerformedJob', 'true');
}



   // Search wise:


   const handleSearch = async () => {
    navigate('/matchedJobs', { state: search })
    sessionStorage.setItem('searchPerformedJob', 'true');
}



  // Category wise:


  const CategoryWiseFilter = async (cat) => {
    const search = {title: cat}
    navigate('/matchedJobs', { state: search,});
    sessionStorage.setItem('searchPerformedJob', 'true');
}



  // Location wise:

  const LocationWiseFilter = async (loc) => {
    const search = {location: loc}
    navigate('/matchedJobs', { state: search,});
    sessionStorage.setItem('searchPerformedJob', 'true');
}
  


  const RemoteFilter = async () => {
    const search = {location: 'Remote'}
    navigate('/matchedJobs', { state: search,});
    sessionStorage.setItem('searchPerformedJob', 'true');
  };

  /*************************Search Option End********************************/





  return (
    <>
      <div className="container-fluid view-more-jobs ">
        <div className="job-form" >
          <div className="mx-auto" >
            <div className="input-box container  text-center mx-auto my-5 pr-5 pl-3" style={{ backgroundColor: "white", borderRadius: "15px", padding: "5px", minHeight: "70px" }}>
              <svg
                style={{ marginTop: "25px", marginLeft: "18px" }}
                xmlns="http://www.w3.org/2000/svg"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-search"
              >
                <circle cx={11} cy={11} r={8} />
                <line x1={21} y1={21} x2="16.65" y2="16.65" />
              </svg>

              <input
                style={{ outline: "none", border: "none", marginTop: "3px", marginLeft: "10px", fontSize: "20px" }}
                type="text"
                placeholder="Job, title, keyword"
                name="title"
                id="title"
                onChange={handleChange}
                value={search.title}
              />
              <svg
                onClick={removeTitle}
                style={{ marginLeft: "-40px", marginTop: "-2px", cursor: 'pointer' }}
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
                id="IconChangeColor">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  id="mainIconPathAttribute">
                </path>
              </svg>
            </div>



            <div className="input-box container  text-center mx-auto my-5 pr-5 pl-3" style={{ backgroundColor: "white", borderRadius: "15px", padding: "5px", minHeight: "70px" }}>
              <svg
                style={{ marginTop: "22px", marginLeft: "18px" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                id="IconChangeColor"
                height="22"
                width="22">
                <g>
                  <path fill="none"
                    d="M0 0h24v24H0z"
                    id="mainIconPathAttribute">
                  </path>
                  <path
                    d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                    id="mainIconPathAttribute">
                  </path>
                </g>
              </svg>


              <input
                style={{ outline: "none", border: "none", marginTop: "3px", marginLeft: "10px", fontSize: "20px" }}
                type="text"
                placeholder="City, state or zip code"
                name="location"
                id="location"
                onChange={handleChange}
                value={search.location}
              />
              <svg
                onClick={removeLocation}
                style={{ marginLeft: "-40px", marginTop: "-2px", cursor: 'pointer' }}
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
                id="IconChangeColor">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  id="mainIconPathAttribute">
                </path>
              </svg>

            </div>
            <div className="ml-auto text-center py-3">
            <button className="findJobBtn" onClick={handleSearch}
              //style={{ fontSize: "15px", paddingLeft: "50px", paddingRight: "50px", backgroundColor: "#4B92C8", color: "white", borderRadius: "15px" }}
              >
                {loading ? <Spinner /> : <span>Find Jobs</span>}</button>

            </div>

          </div>




          {/* <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx={11} cy={11} r={8} /><line x1={21} y1={21} x2="16.65" y2="16.65" /></svg>
                        <input type="text" placeholder='Job, Title, Keyword' />
                    </div>
                    <div className="input-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle cx={12} cy={10} r={3} /></svg>
                        <input type="text" placeholder='City, State, Zipcode' />
                    </div>
                    <button className="btn btn-primary search">Find Jobs</button> */}
        </div>
      </div>


      <div className="container" style={{ maxWidth: "1450px" }}>
        <div className="job-by-profession pt-5 px-2 " style={{ marginTop: "30px" }}>
          <h3 className="title">Jobs By Professional Fields</h3><small><a href="/matchedJobs" className="float-right mr-5" onClick={seeAll}>See all</a></small>
          <div className="row mt-4">
          <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={prof4} alt="Accounting & Finance" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Accounting & Finance")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={prof7} alt="Advertising" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Advertising")}
                   style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center " style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={bs} alt="Business Service" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Business Service")}
                   style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={chemical} alt="Chemical" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Chemical")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={civil} alt="Civil" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Civil")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={prof3} alt="Computer Science" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Computer Science")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={cw} alt="Content Writing" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Content Writing")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={customer} alt="Customer Service" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Customer Service")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={electronics} alt="Electronics" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Electronics")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={prof5} alt="Engineering" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Engineering")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={prof8} alt="Health Care" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Health Care")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={prof6} alt="Legal" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Legal")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={manufacturing} alt="Manufacturing" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Manufacturing")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={prof2} alt="Marketing" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Marketing")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={machanical} alt="Mechanical" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Mechanical")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={other} alt="Other" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Other")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={restaraunt} alt="Restaurant" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Restaurant")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={retails} alt="Retail" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Retail")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={prof1} alt="Sales" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Sales")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={transport} alt="Transportation" className="img-fluid"
                    onClick={() => CategoryWiseFilter("Transportation")}
                    style={{ cursor: "pointer", borderRadius:"10px"}}
                  />
                </a>
              </div>
            </div>
          </div>
          {/* <div className="professions">
          <a href="/" className="profession">
            Sales
          </a>
          <a href="/" className="profession">
            Science & Technology
          </a>
          <a href="/" className="profession">
            Healthcare
          </a>
          <a href="/" className="profession">
            IT Sector
          </a>
          <a href="/" className="profession">
            Business Administration
          </a>
          <a href="/" className="profession">
            Consulting
          </a>
          <a href="/" className="profession">
            Law & Publice Policies
          </a>
          <a href="/" className="profession">
            Cybersecurity
          </a>
          <a href="/" className="profession">
            Financial
          </a>
          <a href="/" className="profession">
            Architecture & Engineering
          </a>
          <a href="/" className="profession">
            Art, Culture and Management
          </a>
          <a href="/" className="profession">
            Community & Socail Services
          </a>
        </div> */}
        </div>
        <div className="job-by-profession px-2" style={{ marginTop: "-85px" }}>
          <h3 className="title">Top Jobs Location</h3><small><a href="/matchedJobs" className="float-right mr-5" onClick={seeAll}>See all</a></small>
          <div className="row mt-4">
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center " style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={job1} alt="Remote" className="img-fluid"
                    onClick={RemoteFilter}
                    style={{ cursor: "pointer" }}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={job2} alt="Delhi" className="img-fluid"
                    onClick={() => LocationWiseFilter("Delhi")}
                    style={{ cursor: "pointer" }}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={job3} alt="Mumbai" className="img-fluid"
                    onClick={() => LocationWiseFilter("Mumbai")}
                    style={{ cursor: "pointer" }}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 my-3 text-center" style={{ minWidth: "200px" }}>
              <div className="profession">
                <a href="#">
                  <img src={job4} alt="Bangalore" className="img-fluid"
                    onClick={() => LocationWiseFilter("Bangalore")}
                    style={{ cursor: "pointer" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
