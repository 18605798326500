import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./css/AboutUs.scss";
import { useSelector } from "react-redux";
function AboutUs() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
    }, []);

    const {user} = useSelector((state) => state.user)
    return (
        <>
            <div className="about-wrapper" style={{ backgroundColor: "white" }}>
                <h1 className="aboutHeading">About us</h1>
                <p className="subHeading">Creating a world of work without limits</p>
                <br />
                <br />
                <h1 className="contentHeading">Our Story</h1>

                <p className="aboutContent">The Workcroft story begins over 6 years ago when a visionary leader from leading think tanks in North America realized her close friend in India would be perfect for a data science project. The team agreed she was the best choice, but were concerned about working with someone halfway around the globe.</p>
                <br />

                <h1 className="contentHeading">A new way of working is born</h1>

                <p className="aboutContent">In response, the geeks created a new web-based platform that brought visibility and trust to remote work, offshore teams, and contract work. It was so successful the geeks realized other businesses would also benefit from reliable access to a larger pool of quality talent, while workers would enjoy the freedom and flexibility to find jobs online. Together the geeks decided to start a portal that would deliver on the promise of this technology.</p>

                <p className="aboutContent">Fast-forward to today, that technology is the foundation of Workcroft - the world’s leading project marketplace portal. With thousands of projects posted on Workcroft annually, freelancers are earning more via the site each year and providing companies with over 100+ skills.</p>

                <br />
                <h1 className="contentHeading">A world of opportunities</h1>

                <p className="aboutContent">Through Workcroft businesses get more done, connecting with freelancers to work on projects and assignments from machine learning, AI, generative AI, Artificial Intelligence, Augmented Reality, AR, Virtual Reality, VR, big data, data mining, data ETL, analytics, financial analysis, research, web and mobile app development to SEO, social media marketing, content writing, graphic design, admin help and thousands of other projects. Workcroft makes it fast, simple, and cost-effective to find, hire, work with, and pay the best professionals anywhere, any time.</p>
                <br />

                <h1 className="contentHeading">Workcroft's vision</h1>
                <p className="aboutContent">To connect cutting-edge businesses with great talent to work without limits.</p>
                <br />
                <b style={{ fontSize: "30px", color: "#3D3D3D" }}>Address:</b>
                <br />
                <p className="aboutContent">4/2, E-Extn, Shyam Vihar Phase 1, Najafgarh, Delhi - 110043, India</p>
                <br />
                <p className="aboutContent">Contact Number</p>
                <p className="aboutContent">India, Phone: (+91) 7428477468</p>
                <br />
                <p className="aboutContent">Business Name</p>
                <p className="aboutContent">Blackcoffer Private Limited</p>
                <br />

                <h1 className="startJourney">Start your journey</h1>

                <div className="button" style={{ display: "flex" }}>

                    <a href={!user ? "/signup" : "/freelancer-details"} style={{ color: "white", font: "20px" }}>
                        <button>Find Talent</button>
                    </a>

                    <a href={!user ? "/signup" : "/matchedHourlyProject/all"} style={{ color: "white", font: "20px" }}>
                        <button>Find Work</button>
                    </a>
                </div>
            </div>
        </>
    );
}

export default AboutUs;
