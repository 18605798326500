import React, { useState, useEffect } from "react";
import "./UserApplications.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { MONTH_DETAILS } from "../data";
import { CgFileDocument } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import "./Jobs.scss";
import { useDispatch } from "react-redux";
import { loadUser } from "../../actions/userActions";
import { externalIcon } from "../../assets";
import ApplicationTable from "./ApplicationTable";
function UserApplications() {
    const openPreviewPageInNewTab = (state) => {
        const data = state;
        window.open(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());
    };

    const openReviewPageInNewTab = (state) => {
        window.open(`/review/${state.application._id}`);
    };

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
    }, []);

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [userApplicationData, setUserApplicationData] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const getUserData = async (page) => {
        setLoading(true);
        try {
            const { data } = await axios.post(
                "/api/user/myApplyJobs",
                { page },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );
            setUserApplicationData(data.jobs);
            setTotalPage(data.totalPages);
            setLoading(false);
        } catch (error) {
            console.log(error.response.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserData(page);
        window.scrollTo(0, 0);
    }, [page]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPage) {
            setPage(newPage);
        }
    };
    useEffect(() => {
        dispatch(loadUser());
    }, [dispatch]);

    // console.log(userApplicationData, "userApplicationData");

    return (
        <div className="my-applications">
            {loading ? (
                <>
                    <div className="my-auto" style={{ textAlign: "center" }}>
                        <p
                            style={{
                                color: "#4B92C8",
                                fontWeight: "500",
                                fontSize: "20px",
                                marginLeft: "43px",
                                marginBottom: "5px",
                            }}
                        >
                            Loading..
                        </p>
                        <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "27px" }} />
                    </div>
                </>
            ) : (
                <>
                    {userApplicationData.length !== 0 ? (
                        <>
                            <h3 className="title">My applications</h3>
                            {/* <div className="tableWrapper" >

                                <ApplicationTable userApplicationData={userApplicationData}/>
                            </div> */}
                            
                            <div className="table-box table-responsive border">
                                <table className="applications table">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>Project Type</th>
                                            <th style={{ textAlign: "center",width:"500px" }}>
                                                Project Title
                                            </th>
                                            <th className="" style={{ textAlign: "center" }}> Applied On </th>
                                            <th className="" style={{ textAlign: "center" }}>
                                                Number Of Applicant
                                            </th>
                                            <th style={{width:"600px" }}>Application Status</th>
                                            <th  style={{width:"200px" }}>
                                                Review Application
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userApplicationData.map((application, index) => (
                                            application?.job && (
                                                <tr key={index}>
                                                <td className="smallTd" style={{ fontSize: "14px", paddingInline: "1rem", textAlign: "start",paddingLeft:"30px",width:"200px" }}>{application?.job?.opportunityType} </td>

                                                <td style={{ fontSize: "14px", textAlign: "start", paddingLeft: "10px", width:"500px"}} 
                                                    className="usertableheadingstyle smallTdProfile">
                                                    {application?.job?.profile}
                                                    <img src={externalIcon} alt="externalIcons" onClick={() => openPreviewPageInNewTab(application.job)} style={{cursor:"pointer",width:"15px" , height:"15px", objectFit:"cover",marginLeft:"5px"}}/>
                                                </td>

                                                <td style={{ fontSize: "15px", textAlign: "center",width:"100px" }} className="usertableheadingstyle smallTdDate">
                                                    {(application?.appliedDate).slice(8, 10) + " " + MONTH_DETAILS[parseInt(application?.appliedDate.slice(5, 7), 10)] + "' " + application.appliedDate.slice(2, 4)}
                                                </td>
                                                <td style={{ fontSize: "15px", textAlign: "center",width:"80px" }}>{application?.job?.userApplied?.length}</td>
                                                <td style={{ fontSize: "15px", textAlign: "center" }}>
                                                    <p
                                                        style={{
                                                            background: "#EAF8FE",
                                                            color: "#2880E4",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            letterSpacing: "0.6px",
                                                            padding: "4px 20px",
                                                            borderRadius: "20px",
                                                            display: "inline",
                                                            width:"600px"
                                                        }}
                                                    >
                                                        {application && application?.status === "Application_Sent" ? <>Applied</> : <>{application?.status === "NotIntrested" ? <>Not Intrested</> : <>{application?.status === "Assignment" ? <>Assignment Sent</> : <>{application?.status === "Interviews" ? <>Interview Scheduled</> : <>{application?.status}</>}</>}</>}</>}
                                                    </p>
                                                </td>
                                                
                                                <td style={{ fontSize: "15px", textAlign: "center",width:"60px" }}>
                                                    <CgFileDocument className="docIcon" onClick={() => openReviewPageInNewTab({ application })} />
                                                </td>

                                                
                                            </tr>
                                            )
                                            
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* pagination */}
                            {userApplicationData && userApplicationData.length > 15 ? (
                                <>
                                    <div className="pagination" style={{ marginTop: "-30px" }}>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-center">
                                                <li className={`page-item ${page <= 1 ? "disabled" : ""}`}>
                                                    <p tabIndex="-1" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                                                        <IoIosArrowBack className={page <= 1 ? "paginationBtndisabled" : "paginationBtn"} style={{ marginTop: "-2px" }} />
                                                    </p>
                                                </li>
                                                <li className="page-item disabled">
                                                    <p>{`${page} / ${totalPage}`}</p>
                                                </li>
                                                <li className={`page-item ${page >= totalPage ? "disabled" : ""}`}>
                                                    <p onClick={() => handlePageChange(page + 1)} disabled={page === totalPage}>
                                                        <IoIosArrowForward className={page >= totalPage ? "paginationBtndisabled" : "paginationBtn"} style={{ marginTop: "-2px" }} />
                                                    </p>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <>
                            <i className="fa fa-window-close-o mx-auto" aria-hidden="true" style={{ fontSize: "225px", color: "#343434" }}></i>
                            <h1 style={{ marginTop: "-30px", color: "#343434" }}>No Application Found</h1>

                            <div style={{ textAlign: "center", marginTop: "-20px" }}>
                                <button className="NoApplicationButtons" onClick={() => navigate("/matchedHourlyProject/all")}>
                                    Hourly Projects
                                </button>
                                <button className="NoApplicationButtons2" onClick={() => navigate("/matchedFixedProject/all")}>
                                    Fixed Cost Projects
                                </button>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default UserApplications;
