import React from "react";
import "./PreviewJob.scss";
import "./Preview.css";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Popup from "reactjs-popup";
import { addEllipsis, extractIdFromUrl, filterSimilerProjects } from "../../utils/helper";
import ProjectDetailsCard from "./ProjectDetailsCard";

const PreviewJob = () => {
    const dispatch = useDispatch();
    const [filled, setFilled] = useState(false);
    const [state2, setState2] = useState(null); // Assuming state2 is your state variable
    const [similerProjects, setSimilerProjects] = useState([]);
    const [activityData, setActivityData] = useState(null);
    const [applicationData, setApplicationData] = useState(null);

    const { jobId } = useParams();

    // Extracting the ID part after the last underscore
    const jobId2 = jobId.substring(jobId.lastIndexOf("_") + 1);

    const getLastViewByClient = async (email) => {
        //console.log(email, "user email");
        await axios
            .post("/api/user/get-activity-record", {
                email: email,
            })
            .then((res) => {
                console.log(res?.data, "result");
                setActivityData(res?.data);
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.post("/api/user/previewdetails", {
                    Id: jobId2,
                });
                getLastViewByClient(data?.email);
                setState2(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [jobId]);

    useEffect(() => {
        dispatch(loadUser());
    }, []);

    const navigate = useNavigate();
    const { state } = useLocation();
    const state1 = state ? state : JSON.parse(localStorage.getItem("viewJob"));
    const { user } = useSelector((state) => state.user);

    const [showModal, setShowModal] = useState(false);

    const handleBuyConnects = () => {
        navigate("/freelancerbuyconnects");
        setShowModal(false);
    };
    const viewCount = async () => {
        const id = state2 && state2._id;
        console.log("view count id",id);
        
        try {
            await axios.post("/api/user/viewcount", { id });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
        viewCount();
    }, []);

    useEffect(() => {
        viewCount();
    }, [state2]);

    const openPreviewPageInNewTab = (state) => {
        const data = state;
        const url = `/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
        return url;
    };

    const getSimillerProjects = async () => {
        try {
            const res = await fetch("/api/user/getAllJobs");
            const data = await res.json();
            // Sort the jobs array by datePosted in descending order
            const sortedJobs = data.jobs.sort((a, b) => new Date(b.datePosted) - new Date(a.datePosted));
            const filtered = filterSimilerProjects(sortedJobs, state2?.skill, state2?._id);
            setSimilerProjects(filtered);
            //console.log(sortedJobs);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getCandidateData = async () => {
        try {
            await axios
                .post(
                    "/api/employer/get-applied-data",
                    { jobId: jobId2 },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.getItem("token"),
                        },
                    }
                )
                .then((result) => {
                    console.log(result?.data, "this is applied job result");
                    setApplicationData(result?.data?.RESULT_DATA);
                });
        } catch (error) {
            console.log(error, "this is applied job error");
        }
    };

    useEffect(() => {
        getSimillerProjects();
        getCandidateData();
    }, [state2]);

    console.log(state2, "project preview");

    return (
        <>
            <div className="reviewpage1 container-fluid py-5" style={{ backgroundcolor: "white" }}>
                {state2 && (
                    <>
                        <Helmet>
                            <meta property="og:title" content={`${state2.profile} ${state2.jobType === "Remote" ? state2.jobType + " " : " "} ${state2.opportunityType}`} />
                            <title>{state2.profile + " " + (state2.jobType === "Remote" ? state2.jobType + " " : " ") + state2.opportunityType}</title>
                        </Helmet>

                        <title>{state2.profile + " " + (state2.jobType === "Remote" ? state2.jobType + " " : " ") + state2.opportunityType}</title>

                        <div className="headingText">
                            <h1 className=" pt-5 " style={{ fontSize: "30px" }}>
                                {state2.profile}
                                {/* {state2.jobType === "Remote" ? <> work from home</> : <></>}{" "}
              {state2.opportunityType.toLowerCase()}{" "}
             
              {state2.jobType === "Remote" ? (
                <></>
              ) : (
                <>
                  {" "}
                  {state2.city && state2.city.length > 0
                    ? state2.city.map((city) => "in" + " " + city + " ")
                    : ""}
                </>
              )} */}
                            </h1>
                        </div>
                        <ProjectDetailsCard state2={state2} isApply={true} user={user} activityData={activityData} setShowModal={setShowModal} appliedData={applicationData} />
                        <div className="r1">
                            <div className="sectionn11 mb-3 border" >
                                <h3 style={{ fontSize: "20px" }} className="title pt-3">
                                    Similar Projects on Workcroft
                                </h3>
                                {similerProjects?.length === 0 ? (
                                    <div
                                        style={{
                                            marginTop: "20px",
                                        }}
                                    >
                                        <p style={{ color: "gray", fontSize: "14px" }}>There is no similar projects right now!!</p>
                                    </div>
                                ) : (
                                    <div style={{ marginTop: "10px" }}>
                                        {similerProjects && similerProjects.slice(0,10)?.map((item, index) => (
                                            <div
                                                key={index}
                                                style={{marginBottom:"5px",
                                                }}
                                            >
                                                <a
                                                    href={openPreviewPageInNewTab(item)}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    style={{
                                                        color: "#288acb",
                                                        fontSize: "15px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {item?.profile}
                                                </a>{" "}
                                                <spna style={{ fontSize: "14px" }}>Project Description: {addEllipsis(item?.jobDescription, 100)}</spna>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Popup open={showModal} closeOnDocumentClick onClose={() => setShowModal(false)}>
                <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                    <div className="popup-containernew">
                        <div className="close-button12345" onClick={() => setShowModal(false)}>
                            <RxCross1 />
                        </div>
                        <div className="success-message mx-3">Your Connect credit limit has been reached.</div>
                        <div className="success-message1 mx-3">To continue posting, kindly purchase more Connects.</div>
                        <div className="d-flex flex-column flex-md-row gap-5" style={{ marginTop: "10px", marginBottom: "20px" }}>
                            <button type="button" className="submit-btn12345" onClick={handleBuyConnects}>
                                Buy Connects
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    );
};

export default PreviewJob;
