import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { directContract_1, directContract_2, directContract_3 } from "../../assets";
import "./css/DirectContract.scss";

const DirectContract = () => {

    useEffect(() => {
        window.scroll(0,0);
    },[])
    const steps = [
        {
            number: 1,
            title: "Review the contract",
            description: "Your talent will send you a copy via email.",
        },
        {
            number: 2,
            title: "Accept the terms",
            description: "Accept and use any secure platform that freelancers wishes to get paid using, paypal, wise, worldremit, payoneer, direct transfer, UPI, etc.",
        },
        {
            number: 3,
            title: "Approve the payment",
            description: "Check the work, deliverables and approve or process the payment for freelancers accordingly.",
        },
    ];
    return (
        <div className="direct-contract-container">
            {/* Hero Section */}
            <div className="hero-section">
                <div className="hero-content">
                    <div className="">
                        <h1>Direct Contracts for hassle-free payments</h1>
                        <p>Simplify payments to global freelancers with Direct Contracts.</p>
                        <Link to="/learn-more">
                        <button className="primary-btn">Learn More</button>
                        </Link>
                    </div>
                    <p className="signup-text">
                        Are you a freelancer? View information 
                        <Link to="/talent-direct-contracts" className="signup-link">
                            &nbsp;here
                        </Link>
                    </p>
                </div>
                <div className="hero-image">
                    <img src={directContract_1} alt="Person working on laptop" />
                </div>
            </div>

            <section className="how-it-works">
                <h2>How to pay freelancers</h2>
                <div className="steps-container">
                    {steps.map((step, index) => (
                        <div key={index} className="step-item">
                            <div className="step-number">{step.number}</div>
                            <div className="content">
                                <h3>{step.title}</h3>
                                <p>{step.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className="reasons">
                <div className="reasons__content">
                    <div className="reasons__image">
                        <img src={directContract_2} alt="Team meeting in modern office space" />
                    </div>
                    <div className="reasons__text">
                        <h2>Why Direct Contracts?</h2>

                        <div className="reasons__list">
                            <div className="reason">
                                <h3>Your payment is secure</h3>
                                <p>Make sure you do confirm this with your freelancer before making any payment.</p>
                            </div>

                            <div className="reason">
                                <h3>Choose your preferred payment method</h3>
                                <p>Pay talent globally via credit card or PayPal, and ACH bank transfers in the U.S.A, or payoneer, or Wise, or Worldremit or UPI or any method that you preffer.</p>
                            </div>

                            <div className="reason">
                                <h3>Assistance with resolving disputes</h3>
                                <p>If you encounter a problem with your project, we can intervene to assist.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="discover">
                <div className="discover__content">
                    <div className="discover__icon">
                        <img src={directContract_3} alt="" className="search-icon" />
                    </div>

                    <div className="discover__text">
                        <h2>Pay for projects safely and securely</h2>
                        <Link to="/learn-more">
                        <button className="button button--success">Learn More</button>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DirectContract;
