import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const StudentProtectedRoute = ({ Component }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const userType = localStorage.getItem("userType");

        if (!userType) {
            // Save full path including search params and hash
            const fullPath = location.pathname + location.search + location.hash;
            console.log("from student route",fullPath);
            
            localStorage.setItem("intendedPath", fullPath);
            navigate("/login");
        } else if (userType !== "User") {
            navigate("/notfound");
        }
    }, [navigate, location]);
    return <>{localStorage.getItem("userType") === "User" ? <Component /> : null}</>;
};

export default StudentProtectedRoute;
