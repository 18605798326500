import React from "react";

const CategoryShimmer = () => {
  const categories = Array(10).fill(null);
  
  return (
    <div className="shimmer-grid">
      {categories.map((_, index) => (
        <div key={index} className="shimmer-card">
          {/* Shimmer overlay */}
          <div className="shimmer-overlay" />
          
          {/* Title placeholder */}
          <div className="title-placeholder" />
          
          {/* Rating placeholder */}
          <div className="rating-container">
            <div className="star-placeholder" />
            <div className="rating-placeholder" />
          </div>
          
          {/* Skills count placeholder */}
          <div className="skills-placeholder" />
        </div>
      ))}
      
      <style>{`
        .shimmer-grid {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 16px;
          padding: 16px;
          width:100%;
        }

        @media (min-width: 768px) {
          .shimmer-grid {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        @media (min-width: 1024px) {
          .shimmer-grid {
            grid-template-columns: repeat(4, 1fr);
          }
        }

        .shimmer-card {
          position: relative;
          overflow: hidden;
          background-color: #f3f4f6;
          border-radius: 8px;
          padding: 16px;
          height: 128px;
          width:250px;
        }

        .shimmer-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.4),
            transparent
          );
          animation: shimmer 1.5s infinite;
          transform: skewX(-20deg);
        }

        .title-placeholder {
          height: 24px;
          background-color: #e5e7eb;
          border-radius: 4px;
          width: 75%;
          margin-bottom: 16px;
        }

        .rating-container {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 8px;
        }

        .star-placeholder {
          height: 16px;
          width: 16px;
          background-color: #e5e7eb;
          border-radius: 4px;
        }

        .rating-placeholder {
          height: 16px;
          width: 64px;
          background-color: #e5e7eb;
          border-radius: 4px;
        }

        .skills-placeholder {
          height: 16px;
          width: 96px;
          background-color: #e5e7eb;
          border-radius: 4px;
        }

        @keyframes shimmer {
          0% {
            transform: translateX(-100%) skewX(-20deg);
          }
          100% {
            transform: translateX(200%) skewX(-20deg);
          }
        }
      `}</style>
    </div>
  );
};

export default CategoryShimmer;