import React, { useState, useEffect } from "react";
// import "./css/Support.scss";
import "../Other/css/AboutUs.scss";
import { MdOutlineArrowBack } from "react-icons/md";
import contactusLogo from "../../assets/images/contactus.png";
import mail from "../../assets/images/mail.png";
import chat from "../../assets/images/Vector.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Layout/Spinner";
import { useNavigate, useLocation } from "react-router-dom";
import { contact_1, contact_2 } from "../../assets";
import { useRef } from "react";
function Support() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const closeMailUs = useRef(null);
    const closeTextUs = useRef(null);
    const [loading2, setLoading2] = useState(false);
    const [mailUs, setMailUs] = useState({
        email: "",
        message: "",
        senderId: user && user._id,
    });
    const [textUs, setTextUs] = useState({
        email: "",
        message: "",
    });

    const handleInputMailUs = (e) => {
        setMailUs({ ...mailUs, [e.target.name]: e.target.value });
    };

    const handleInputTextUs = (e) => {
        setTextUs({ ...textUs, [e.target.name]: e.target.value });
    };

    const handleSubmitMailUs = async () => {
        
        setLoading(true);
        if (mailUs.email === "") {
            toast.error("Enter email");
            setLoading(false);
        } else if (mailUs.message === "") {
            toast.error("Enter message");
            setLoading(false);
        } else {
            try {
                const { data } = await axios.post("/api/send-email", {
                    email: mailUs.email,
                    message: mailUs.message,
                    senderId: user && user._id,
                });
                toast.success(data.message);
                setMailUs({ ...mailUs, email: "", message: "" });
                setLoading(false);
                closeMailUs.current.click();
            } catch (err) {
                console.error("Error fetching data: " + err);
                toast.error("Internal server error");
                setLoading(false);
            }
        }
    };

    const handleSubmitTextUs = async () => {
        
        setLoading2(true);
        if (textUs.email === "") {
            toast.error("Enter email");
            setLoading2(false);
        } else if (textUs.message === "") {
            toast.error("Enter message");
            setLoading2(false);
        } else {
            try {
                const { data } = await axios.post("/api/send-email", {
                    email: textUs.email,
                    message: textUs.message,
                    senderId: user && user._id,
                });
                toast.success(data.message);
                setTextUs({ ...textUs, email: "", message: "" });
                setLoading2(false);
                closeTextUs.current.click();
            } catch (err) {
                console.error("Error fetching data: " + err);
                toast.error("Internal server error");
                setLoading2(false);
            }
        }
    };
    const handleMailUsClick = (e) => {
        e.preventDefault();
        
        if (!user) {
          navigate("/login");
        } else {
          const mailUsModal = new window.bootstrap.Modal(document.getElementById("mailUs"));
          mailUsModal.show();
        }
      };
      const handleTextUsClick = (e) => {
        e.preventDefault();
        
        if (!user) {
          navigate("/login");
        } else {
          const mailUsModal = new window.bootstrap.Modal(document.getElementById("textUs"));
          mailUsModal.show();
        }
      };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="contactus-wrapper" style={{ backgroundColor: "white",marginTop:"13rem" }}>
                <h1 className="subHeading">Help & Support</h1>
                <p className="contactContent">We're here to help you! Find answers to your questions, get in touch with our support team.</p>

                <h1 className="contentHeading" style={{ marginTop: "4rem" }}>
                    Information
                </h1>
                <p className="contactContent">The Workcroft story begins over 6 years ago when a journalist from leading think tanks in North America realized her close friend in India would be perfect for a data science project. The team agreed she was the best choice, but were concerned about working with someone halfway around the globe.</p>

                <p className="contactContent" style={{ marginTop: "3rem",color:"#393939" }}>
                    For any query, please write an email to hello@workcroft.com
                </p>

                <div className="support-cards">
                    <div className="support-card support-card--mail">
                        <div className="support-card__content">
                            <div className="support-card__text">
                                <div className="support-card__innerContent">
                                    <h2>Talk to us</h2>
                                    <p>Having a difficulty with something e-mail us</p>
                                </div>

                                <a href="#" className="support-card__link" onClick={handleMailUsClick}>
                                    Mail Us
                                    <svg viewBox="0 0 24 24" className="support-card__arrow" fill="none" stroke="currentColor">
                                        <path d="M5 12h14m-7-7l7 7-7 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </a>
                            </div>
                            <div className="support-card__icon">
                                <img src={contact_1} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="support-card support-card--text">
                        <div className="support-card__content">
                            <div className="support-card__text">
                                <div className="support-card__innerContent">
                                    <h2>Text Customer Support</h2>
                                    <p>Sometimes you need a little help from your friends. Or our support rep. Don't worry.. we're here for you.</p>
                                </div>
                                <a href="#" className="support-card__link" onClick={handleTextUsClick}>
                                    Text Us
                                    <svg viewBox="0 0 24 24" className="support-card__arrow" fill="none" stroke="currentColor">
                                        <path d="M5 12h14m-7-7l7 7-7 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </a>
                            </div>

                            <div className="support-card__icon">
                                <img src={contact_2} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <p className="contactContent" style={{ display: "flex" }}>
                    {" "}
                    <span>Address:</span>
                    &nbsp; 4/2, E-Extn, Shyam Vihar Phase 1, Najafgarh, Delhi - 110043, India
                </p>
                <br />
                <p className="contactContent" style={{ display: "flex" }}>
                    <span>Phone Number:</span>&nbsp; +91 7428477468
                </p>
                <br />

                <p className="contactContent" style={{ display: "flex" }}>
                    <span>Email id:</span>&nbsp; hello@workcroft.com
                </p>
                <br />
                <p className="contactContent" style={{ display: "flex" }}>
                    <span>Website:</span> <a href="https://workcroft.com">&nbsp; www.workcroft.com</a>
                </p>
            </div>
            
            <div className="modal fade" id="mailUs" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ borderRadius: "20px" }}>
                    <div className="modal-content PopupBox" style={{ borderRadius: "20px" }}>
                        <div className="modal-body my-4" style={{ borderRadius: "20px" }}>
                            <div style={{ width: "30px", marginLeft: "auto", marginTop: "9px" }}>
                                <button type="button" className="btn-close" ref={closeMailUs} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div>
                                <p style={{ fontSize: "30px", fontWeight: "500", color: "#000000" }}>
                                    <img src={mail} alt=".." style={{ width: "30px", height: "26px", marginRight: "8px", marginTop: "-5px" }} /> Talk to us
                                </p>
                            </div>

                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "-5px" }}>Having a difficulty with something e-mail us</p>
                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "40px" }}>Enter Your Email</p>
                            <input className="" type="text" style={{ width: "100%", borderRadius: "10px", fontSize: "18px", marginTop: "5px", padding: "20px", border: "solid 1px #959595", outline: "none" }} name="email" value={mailUs.email} onChange={handleInputMailUs} />
                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "30px" }}>Message</p>
                            <textarea rows={5} style={{ width: "100%", borderRadius: "10px", padding: "10px", border: "solid 1px #959595", outline: "none" }} name="message" value={mailUs.message} onChange={handleInputMailUs} />

                            <div className="text-end">
                                {loading ? (
                                    <button className="sendAssignmentBtn mt-4" style={{ width: "150px" }}>
                                        <Spinner />
                                    </button>
                                ) : (
                                    <button className="sendAssignmentBtn mt-4" onClick={handleSubmitMailUs}>
                                        Send Email
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Model 2: Text Us */}
            <div className="modal fade" id="textUs" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ borderRadius: "20px" }}>
                    <div className="modal-content PopupBox" style={{ borderRadius: "20px" }}>
                        <div className="modal-body my-4" style={{ borderRadius: "20px" }}>
                            <div style={{ width: "30px", marginLeft: "auto", marginTop: "9px" }}>
                                <button type="button" className="btn-close" ref={closeTextUs} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div>
                                <p style={{ fontSize: "30px", fontWeight: "500", color: "#000000" }}>
                                    <img src={chat} alt=".." style={{ width: "28px", height: "28px", marginRight: "8px", marginTop: "-5px" }} />
                                    Text Customer Support
                                </p>
                            </div>

                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "-5px" }}>Sometimes you need a little help from your friends. Or our support rep. Don't worry.. we're here for you.</p>
                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "40px" }}>Enter Your Email</p>
                            <input className="" type="text" style={{ width: "100%", borderRadius: "10px", fontSize: "18px", marginTop: "5px", padding: "20px", border: "solid 1px #959595", outline: "none" }} name="email" value={textUs.email} onChange={handleInputTextUs} />
                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "30px" }}>Message</p>
                            <textarea rows={5} style={{ width: "100%", borderRadius: "10px", padding: "10px", border: "solid 1px #959595", outline: "none" }} name="message" value={textUs.message} onChange={handleInputTextUs} />

                            <div className="text-end">
                                {loading2 ? (
                                    <button className="sendAssignmentBtn mt-4" style={{ width: "150px" }}>
                                        <Spinner />
                                    </button>
                                ) : (
                                    <button className="sendAssignmentBtn mt-4" onClick={handleSubmitTextUs}>
                                        Text Us
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default Support;
